import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { IoClose } from 'react-icons/io5';
import warningGif from '../../assets/gifs/warning.gif';

const AlertPopup = ({ isOpen, toggle }) => {
  return (
    <Modal size="lg" show={isOpen} onHide={toggle} centered>
      <Modal.Body
        className="p-4 cursor-pointer"
        style={{
          backgroundColor: '#f9f9f9',
          borderRadius: '10px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
        }}
      >
        <div className="text-center position-relative">
          {/* Close Button */}
          <IoClose
            className="text-danger position-absolute"
            style={{ top: '0px', right: '0px', fontSize: '35px', cursor: 'pointer' }}
            onClick={toggle}
          />
          {/* Warning Image */}
          <img
            src={warningGif}
            alt="Warning Animation"
            width="160"
            style={{ maxWidth: '100%', height: 'auto', }}
          />

          <h3 className="fw-bold text-danger mb-3">ALERT</h3>
          <div className="px-4 mx-auto">
            <p className="text-dark fs-5 mb-3">
              As per the DGFT notification dated   <a
                href="https://www.aecci.org.in/wp-content/uploads/2024/12/Rescheduling-of-Launch-Date-for-Preferential-eCoO-2.0-System-reg.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="fw-bold text-danger text-decoration-underline"
              >20th December 2024</a>, to avail the <span className='fw-bold'>Electronic Certificate of Origin (e-COO),</span> applicants must apply through the DGFT Trade Connect platform.
            </p>
            <p className="text-primary fs-5 mb-3">
              When applying, please ensure to select<br /> <span className="fw-bold">‘Asian Exporters Chamber of Commerce & Industry’</span><br /> as the agency for processing the e-COO.
            </p>
            <p className="text-secondary fs-6">
              For any inconvenience, feel free to contact our chamber helpdesk. <strong>022-41271145/46</strong>.
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AlertPopup;
