import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { BsEye } from "react-icons/bs";
import { FaCamera, FaCog, FaCommentDots, FaHourglassEnd, FaMobileAlt, FaShieldAlt, FaWifi } from "react-icons/fa";
import { FaClock } from "react-icons/fa6";
import { useNavigate } from "react-router";
import B2bBanner from "../../../assets/popupBanners/B2bBanner1.png";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { PopUpModalButton } from "../../../components/basic/ModalComponent";
import QuickLinks from "../../../components/basic/QuickLinks";
import Sponsors from "../../../components/basic/Sponsors";
import Layout from "../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import { useAuth } from "../../../context/auth";
import { getURLbyEndPoint, getURLbyEndPointV2 } from "../../../store/api";
import { getAuthUserlocal } from "../../../store/services";
import '../b2b/b2b.css';

const B2bVirtualForumNew = () => {
    return (
        <Layout>
            <Layout2>
                <DashboardHeader />
                <h4 className="mt-4 mb-3">AECCI VIRTUAL B2B FORUM 2025</h4>
                <hr className="mb-2" />
                <img
                    src={B2bBanner}
                    alt="b2b connect 2025"
                    className="img-fluid w-100"
                />
                <hr className='my-2' />
                <div>
                    <div>

                        {getAuthUserlocal().selectMembership !== 'Collaboration' ? (
                            <B2bEventTrack />
                        ) : (
                            <CollaborationReqTrack />
                        )}
                    </div>
                </div>
                <Sponsors />
                <QuickLinks />
            </Layout2>
        </Layout>
    );
};

export default B2bVirtualForumNew;



const calculateTimeRemaining = (date, timeString) => {
    // Parse the date and time
    const [startTime, endTime] = timeString.split(' - ');
    const [time, meridian] = startTime.split(/(?=[AP]M)/);
    const [endTimeOnly, endMeridian] = endTime.split(/(?=[AP]M)/);
    const [inputHours, inputMinutes] = time.split(':').map(num => parseInt(num));
    const [endHours, endMinutes] = endTimeOnly.split(':').map(num => parseInt(num));

    // Convert start time to 24-hour format
    let startHour24 = inputHours;
    if (meridian === 'PM' && inputHours !== 12) {
        startHour24 += 12;
    } else if (meridian === 'AM' && inputHours === 12) {
        startHour24 = 0;
    }

    // Convert end time to 24-hour format
    let endHour24 = endHours;
    if (endMeridian === 'PM' && endHours !== 12) {
        endHour24 += 12;
    } else if (endMeridian === 'AM' && endHours === 12) {
        endHour24 = 0;
    }

    // Create date objects for slot start and end in IST
    const slotStartDate = new Date(date);
    slotStartDate.setHours(startHour24, inputMinutes, 0, 0);

    const slotEndDate = new Date(date);
    slotEndDate.setHours(endHour24, endMinutes, 0, 0);

    // Current time in IST
    const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds (UTC+5:30)
    const currentDate = new Date();
    const istTime = new Date(currentDate.getTime() + (currentDate.getTimezoneOffset() * 60000) + istOffset);

    // Check if current time is within slot time
    if (istTime >= slotStartDate && istTime <= slotEndDate) {
        return 'Slot is Live';
    }

    // Calculate time difference in milliseconds
    const timeDiff = slotStartDate.getTime() - istTime.getTime();

    if (timeDiff <= 0) {
        return 'Session Expired';
    }

    // Convert to days, hours, minutes
    const remainingDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const remainingHours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const remainingMinutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return `${remainingDays}DAYS - ${remainingHours.toString().padStart(2, '0')}HRS - ${remainingMinutes.toString().padStart(2, '0')}MINS`;
};


const B2bEventTrack = () => {
    const [b2bEventTrack, setB2bEventTrack] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);

    const navigate = useNavigate();

    const handleTimeSlotClick = useCallback((
        slotId,
        country,
        time,
        startTime,
        endTime,
        duration,
        date,
        username,
        companyName,
    ) => {
        navigate(`/room/${slotId}?username=${encodeURIComponent(username)}`, {
            state: {
                slotId,
                country,
                time,
                startTime,
                endTime,
                duration,
                date,
                companyName,
            }
        });
    }, [navigate]);

    const fetchTrackData = async () => {
        try {
            const userId = getAuthUserlocal()?._id;
            if (!userId) {
                console.error("No user ID found");
                return;
            }

            const response = await axios.get(getURLbyEndPointV2("getB2bEventRequest") + userId);
            if (response.status === 200 && response.data?.data) {
                const data = response.data.data;

                // Calculate the total amount from all price fields
                const total = data.reduce((sum, item) => sum + (item.price || 0), 0);

                setB2bEventTrack(data);
                setTotalAmount(total);
            }
        } catch (error) {
            console.error("Error fetching Event data:", error);
        }
    };

    useEffect(() => {
        fetchTrackData();
    }, []);



    return (
        <div>
            <Table responsive striped bordered hover >
                <thead>
                    <tr>
                        <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                            Sr<br />No.
                        </th>
                        <th style={{ fontSize: "14px", whiteSpace: "nowrap" }} className="text-center">
                            Booked Countries
                        </th>
                        <th style={{ fontSize: "14px", whiteSpace: "nowrap" }} className="text-center">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {console.log('track', b2bEventTrack)}
                    {b2bEventTrack.length > 0 ? (
                        <>
                            {b2bEventTrack.map((data, index) => (
                                <tr key={`event-${index}`}>
                                    <td>{index + 1}.</td>
                                    <td>
                                        {data.slots.map((slot, slotIndex) => (
                                            <div key={`slot-${slotIndex}`}>
                                                {slot.country} - {slot.time} ({slot.date})
                                            </div>
                                        ))}
                                    </td>
                                    <td
                                        className={`text-center ${data.isApproved === "Processing"
                                            ? "text-primary"
                                            : data.isApproved === "Rejected"
                                                ? "text-danger"
                                                : data.isApproved === "Approved"
                                                    ? "text-success"
                                                    : ""
                                            }`}
                                    >
                                        {data.isApproved && `${data.isApproved}.`}
                                        <div>
                                            {data.receipt &&
                                                <PopUpModalButton
                                                    title="Receipt"
                                                    fileLink={data.receipt}
                                                    disabled={
                                                        data.isApproved ===
                                                        "Processing"
                                                    }
                                                />
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))}

                            <tr>
                                {b2bEventTrack[0].isPaymentReq ? (
                                    <>
                                        <td colSpan={3} className="text-center">
                                            <span> {b2bEventTrack[0].remark}</span>
                                        </td>
                                    </>
                                ) : (

                                    <td colSpan={3} className="text-center">
                                        <PaymentProcess data={b2bEventTrack} totalAmount={totalAmount} />
                                    </td>
                                )
                                }
                            </tr>
                        </>
                    ) : (
                        <tr>
                            <td colSpan={3} className="text-center">
                                No Data Found
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>

            {b2bEventTrack.length > 0 &&
                b2bEventTrack[0].isApproved === "Approved" &&
                b2bEventTrack[0].isPaymentApprove === "Approved" && (
                    <Table responsive striped bordered hover w-auto>
                        <thead>
                            <tr>
                            {["22 Jan"].map(date => (
                                    <th key={date} style={{ width: '200px', textAlign: 'center' }}>{date}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from({
                                length: Math.max(...["2025-01-22"]
                                    .map(date => b2bEventTrack[0]?.slots.filter(slot => slot.date === date).length))
                            })
                                .map((_, rowIndex) => (
                                    <tr key={`row-${rowIndex}`}>
                                        {["2025-01-22"].map((date) => {
                                            const slots = b2bEventTrack[0]?.slots.filter((slot) => slot.date === date);
                                            const slot = slots?.[rowIndex];
                                            const timeRemaining = slot ? calculateTimeRemaining(date, slot.time) : null;
                                            const { name, companyName } = b2bEventTrack[0];

                                            return (
                                                <td key={`${date}-${rowIndex}`} style={{ width: '200px', textAlign: 'center' }}>
                                                    {slot ? (
                                                        <div>
                                                            <Button
                                                                size='sm'
                                                                variant="primary"
                                                                onClick={() => handleTimeSlotClick(
                                                                    slot._id,
                                                                    slot.country,
                                                                    slot.time,
                                                                    slot.startTime,
                                                                    slot.endTime,
                                                                    slot.duration,
                                                                    slot.date,
                                                                    name,
                                                                    companyName
                                                                )}
                                                            >
                                                                <div className='fw-bold'>{slot.country}</div>
                                                                <div className="fw-bold">{slot.time}</div>
                                                            </Button>
                                                            <div>{timeRemaining}</div>
                                                        </div>
                                                    ) : (
                                                        <span>---</span>
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                )}
            <div className="my-2 px-3 border">
                <h6 className="fs-bolder mt-3">
                    Tips for Virtual B2B Forum - Video Conferencing:
                </h6>
                <hr />
                <ul style={{ paddingLeft: "1.2rem", listStyleType: "none" }} className="my-3">
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaCamera style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        Testing link is now available for your audio/video test. Please check the setup before the meeting starts.
                    </li>
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaClock style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        The meeting link will only be activated at the scheduled meeting time.
                    </li>
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaCog style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        If you encounter issues with your camera, ensure that the camera and microphone permissions are enabled in your device settings.
                    </li>
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaShieldAlt style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        If you still have trouble accessing the camera, try temporarily disabling your antivirus software.
                    </li>
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaWifi style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        Make sure you have a stable internet connection to avoid interruptions during the meeting.
                    </li>
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaCommentDots style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        If you leave the meeting early, any chat summary data will be lost, so try to stay until the end.
                    </li>
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaMobileAlt style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        Avoid having devices nearby that might distract you during the meeting.
                    </li>
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaHourglassEnd style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        The meeting window will automatically close after 30 minutes of designated meeting time.
                    </li>
                </ul>
            </div>
        </div>
    );
};


const CollaborationReqTrack = ({ setActivePage }) => {
    const [b2bEventTrack, setB2bEventTrack] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null); // State for the selected slot
    const [represent, setRepresent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();


    const handleShowModal = (slot, currentFiltered) => {
        setSelectedSlot(slot);
        setRepresent(b2bEventTrack[0].represent);
        setShowModal(true);
    };


    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedSlot(null);
    };


    const fetchTrackData = async () => {
        try {
            const userId = getAuthUserlocal()._id;

            const response = await axios.get(
                getURLbyEndPointV2("getInvitationLetter") + userId
            );
            if (response.status === 200) {
                const data = response.data;
                setB2bEventTrack(data.data);
            } else {
            }
        } catch (error) {
            console.error("Error fetching Event data:", error);
        }
    };

    const handleTimeSlotClick = useCallback((
        slotId,
        country,
        time,
        startTime,
        endTime,
        duration,
        date,
        username,
        companyName,
    ) => {
        navigate(`/room/${slotId}?username=${encodeURIComponent(username)}`, {
            state: {
                slotId,
                country,
                time,
                startTime,
                endTime,
                duration,
                date,
                companyName,
            }
        });
    }, [navigate]);


    useEffect(() => {
        fetchTrackData();
    }, []);


    return (
        <div>
            <Table responsive bordered hover w-auto>
                <thead style={{ color: "#333" }}>
                    <tr>
                        <th
                            style={{
                                fontSize: "14px",
                                whiteSpace: "nowrap",
                                padding: "10px",
                            }}
                            className="text-center"
                        >
                            Delegates Details
                        </th>
                        <th
                            style={{
                                fontSize: "14px",
                                whiteSpace: "nowrap",
                                padding: "10px",
                            }}
                            className="text-center"
                        >
                            Participant Details
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{ backgroundColor: "#fff" }}>
                        <td>
                            <div
                                style={{
                                    width: "350px",
                                    maxWidth: "350px",
                                }}
                            >
                                <div style={{ marginBottom: "10px" }}>
                                    <strong style={{ color: "#333", fontSize: "18px" }}>
                                        {getAuthUserlocal().companyName}
                                    </strong>
                                </div>
                                <div style={{ marginBottom: "8px" }}>
                                    <strong style={{ color: "#555" }}>Collaboration No:</strong>{" "}
                                    <span style={{ color: "#888" }}>{getAuthUserlocal().gstNo}</span>
                                </div>
                                <div style={{ marginBottom: "8px" }}>
                                    <strong style={{ color: "#555" }}>Email:</strong>{" "}
                                    <span style={{ color: "#888" }}>{getAuthUserlocal().email}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div
                                style={{
                                    width: "350px",
                                    maxWidth: "350px",
                                }}
                            >

                                <div style={{ marginBottom: "8px" }}>
                                    <strong style={{ color: "#555" }}>Name:</strong>{" "}
                                    <span style={{ color: "#888" }}>
                                        {getAuthUserlocal().firstName}  {getAuthUserlocal().surName}
                                    </span>{" "}
                                    <br />
                                    <strong style={{ color: "#555" }}>Designation:</strong>{" "}
                                    <span style={{ color: "#888" }}> {getAuthUserlocal().role}</span>
                                </div>

                            </div>
                        </td>
                    </tr>
                </tbody>
            </Table>

            {b2bEventTrack.length > 0 && (
                <Table responsive striped bordered hover w-auto>
                    <thead>
                        <tr>
                            <th style={{ width: '200px', textAlign: 'center' }}>22 Jan</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({ length: Math.max(...["2025-01-22"].map(date => b2bEventTrack.filter(slot => slot.date === date).length)) }).map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {["2025-01-22"].map((date) => {
                                    const slots = b2bEventTrack.filter((slot) => slot.date === date);
                                    const slot = slots?.[rowIndex];

                                    const timeRemaining = slot ? calculateTimeRemaining(date, slot.time) : null;


                                    return (
                                        <td key={date + rowIndex} style={{ width: '200px', textAlign: 'center' }}>
                                            {slot ? (
                                                <div>
                                                    <Button
                                                        size='sm'
                                                        key={slot._id}
                                                        variant="primary"
                                                        onClick={() => handleTimeSlotClick(
                                                            slot._id,
                                                            slot.country,
                                                            slot.time,
                                                            slot.startTime,
                                                            slot.endTime,
                                                            slot.duration,
                                                            slot.date,
                                                            getAuthUserlocal().firstName,
                                                            getAuthUserlocal().companyName,
                                                        )}
                                                    >
                                                        <div className="text-white fw-bold">
                                                            {slot.clientId?.companyName}
                                                        </div>
                                                        <div className="fw-bold mb-1">{slot.time} (IST)</div>
                                                    </Button>
                                                </div>
                                            ) : (
                                                <span> --- </span>
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            {/* <JoinSlotDetailsModal
                show={showModal}
                handleClose={handleCloseModal}
                slot={selectedSlot}
                represent={represent}
            /> */}
            <div className="my-2 px-3 border">
                <h6 className="fs-bolder mt-3">
                    Tips for Virtual B2B Forum - Video Conferencing:
                </h6>
                <hr />

                <ul style={{ paddingLeft: "1.2rem", listStyleType: "none" }} className="my-3">
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaCamera style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        Testing link is now available for your audio/video test. Please check the setup before the meeting starts.
                    </li>
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaClock style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        The meeting link will only be activated at the scheduled meeting time.
                    </li>
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaCog style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        If you encounter issues with your camera, ensure that the camera and microphone permissions are enabled in your device settings.
                    </li>
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaShieldAlt style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        If you still have trouble accessing the camera, try temporarily disabling your antivirus software.
                    </li>
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaWifi style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        Make sure you have a stable internet connection to avoid interruptions during the meeting.
                    </li>
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaCommentDots style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        If you leave the meeting early, any chat summary data will be lost, so try to stay until the end.
                    </li>
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaMobileAlt style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        Avoid having active electronic devices nearby that might distract you or affect connectivity during the meeting.
                    </li>
                    <li style={{ position: "relative", paddingLeft: "2rem", marginBottom: "1rem", lineHeight: "1.6" }}>
                        <FaHourglassEnd style={{ position: "absolute", left: "0", top: "0", color: "#007bff", fontSize: "1.2rem" }} />
                        The meeting window will automatically close after 30 minutes of designated meeting time.
                    </li>
                </ul>
            </div>
        </div>
    );
};



// const JoinSlotDetailsModal = ({ show, handleClose, slot, represent }) => {
//     const [selectedParticipant, setSelectedParticipant] = useState("");
//     const navigate = useNavigate();

//     const handleTimeSlotClick = () => {
//         if (selectedParticipant && slot) {
//             navigate(`/room/${slot._id}?username=${encodeURIComponent(selectedParticipant)}`, {
//                 state: {
//                     slotId: slot._id,
//                     country: slot.country,
//                     time: slot.time,
//                     startTime: slot.startTime,
//                     endTime: slot.endTime,
//                     duration: slot.duration,
//                     date: slot.date,
//                 }
//             });
//         } else {
//             console.error("Participant or slot data missing");
//         }
//     };

//     const handleParticipantChange = (e) => {
//         setSelectedParticipant(e.target.value);
//     };

//     if (!slot) {
//         return null;
//     }

//     return (
//         <Modal size="lg"
//             aria-labelledby="contained-modal-title-vcenter"
//             centered show={show} onHide={handleClose}>
//             <Modal.Header closeButton>
//                 <Modal.Title>Slot Details</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 <div className="mb-3">
//                     <strong>Company Name:</strong> {slot?.clientId?.companyName}
//                 </div>
//                 <div className="mb-3">
//                     <strong>Time:</strong> {slot?.time}
//                 </div>
//                 <div className="mb-3">
//                     <strong>Duration:</strong> {slot?.duration}
//                 </div>
//                 <div className="mb-3">
//                     <strong>Date:</strong> {slot?.date}
//                 </div>

//                 <Form.Group controlId="formParticipantSelect" className="mt-4">
//                     <Form.Label><strong>Select Participant</strong></Form.Label>
//                     <Form.Select
//                         value={selectedParticipant}
//                         onChange={handleParticipantChange}
//                         className="mt-2"
//                     >
//                         <option value="">-- Select Participant --</option>
//                         {Array.isArray(represent) && represent.map((rep) => (
//                             <option key={rep._id} value={`${rep.firstName} ${rep.lastName}`}>
//                                 {`${rep.firstName} ${rep.lastName} - ${rep.designation}`}
//                             </option>
//                         ))}
//                     </Form.Select>
//                 </Form.Group>
//             </Modal.Body>
//             <Modal.Footer>
//                 <Button variant="secondary" onClick={handleClose}>
//                     Close
//                 </Button>
//                 <Button
//                     variant="primary"
//                     disabled={!selectedParticipant}
//                     onClick={handleTimeSlotClick}
//                 >
//                     Join
//                 </Button>
//             </Modal.Footer>
//         </Modal>
//     );
// };

function PaymentProcess({ data }) {
    const [modalShow, setModalShow] = useState(false);
    const [addAmount, setAddAmount] = useState(false);
    const [apiSubmitPayment, setApiSubmitPayment] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [auth, setAuth] = useAuth();
    const userId = auth ? auth.user : null;

    const [formFlag, setFormFlag] = useState(false);
    const [formData, setFormData] = useState({
        utrNo: "",
        amount: data[0].totalAmount,
        paymentScreenShot: null,
    });
    const navigate = useNavigate();

    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);

    const user = getAuthUserlocal();
    const walletBalance = user.balance;

    const handleInputChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleFileUpload = (field, file) => {
        if (file.size <= 3 * 1024 * 1024) {
            setFormData((prevData) => ({
                ...prevData,
                [field]: file,
            }));
        } else {
            console.error("File size exceeds 3MB");
            alert("File size exceeds 3MB");
        }
    };

    const handleConfirmPayment = async () => {
        setIsLoading(true)
        const debitAmount = data[0].totalAmount;
        const userId = getAuthUserlocal()._id;

        if (debitAmount === 0) {
            setAddAmount(false);
        } else if (walletBalance < debitAmount) {
            setAddAmount(true);
            return;
        }

        try {
            setIsLoading(true)
            const res = await axios.post(
                getURLbyEndPointV2("processB2bPayment") + userId,
                { debitAmount }
            );

            if (res.data.success) {
                setIsLoading(false)
                alert("Payment processed and slots booked successfully!");
                window.location.reload();
                setApiSubmitPayment(true);
            }
        } catch (error) {
            console.error("Error during payment confirmation:", error);
            setIsLoading(false)
        } finally {
            setApiSubmitPayment(false);
            setIsLoading(false)
        }
    };


    const handleAddFund = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        setFormFlag(true);

        const validationRulesProceedPayment = {
            utrNo: {
                message: "Please Enter UTR Number.",
                isValid: () => formData.utrNo.trim() !== "",
            },
            amount: {
                message: "Please Enter Amount.",
                isValid: () =>
                    formData.amount !== 0 && formData.amount.toString()[0] !== "0",
            },
            amountMin: {
                message: "Please Enter Amount More Than 500.",
                isValid: () => formData.amount >= 500,
            },
            paymentScreenShot: {
                message: "Please Enter Payment Screenshot.",
                isValid: () => formData.paymentScreenShot !== null,
            },
        };

        for (const field in validationRulesProceedPayment) {
            const { message, isValid } = validationRulesProceedPayment[field];
            if (!isValid()) {
                alert(message);
                setFormFlag(false);
                return;
            }
        }

        const apiFormData = new FormData();
        apiFormData.append("utrNo", formData.utrNo);
        apiFormData.append("amount", formData.amount);
        apiFormData.append("paymentScreenShot", formData.paymentScreenShot);
        apiFormData.append("clientId", data.clientId)

        try {
            const res = await axios.post(
                getURLbyEndPoint("addB2bFundToWallet") + userId,
                apiFormData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (res.data.status) {
                alert(res.data.message);
                setFormData({
                    utrNo: "",
                    amount: data[0].totalAmount,
                    paymentScreenShot: null,
                });
                setAddAmount(false);
                setFormFlag(false);
                setIsLoading(false)
                window.location.reload();
            } else {
                alert("Something went wrong!");
                setIsLoading(true)
            }
        } catch (error) {
            alert("Server is not responding!!");
            setIsLoading(false)
            if (error.response) {
                console.error("Response error:", error.response.data);
                console.error("Status code:", error.response.status);
                setIsLoading(false)
            } else if (error.request) {
                console.error("No response received:", error.request);
                setIsLoading(false)
            } else {
                console.error("Request setup error:", error.message);
                setIsLoading(false)
            }
        } finally {
            setFormFlag(false);
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (modalShow && addAmount) {
            setFormData((prevData) => ({
                ...prevData,
                amount: data[0].totalAmount,
            }));
        }
    }, [modalShow, addAmount]);

    return (
        <>
            <Button
                variant="success"
                className="small-font my-1 fw-semibold"
                onClick={handleShow}
                disabled={data.isPaymentReq && data.appNo}
            >
                Process Payment
            </Button>

            <Modal show={modalShow} onHide={handleClose} centered>
                <Modal.Body>
                    <div>
                        <h5>Payment Process</h5>
                        <hr />
                        <Card>
                            <Card.Header>
                                <Row className="d-flex">
                                    <Col md={7}>
                                        <h6 className="text-medium">{user.companyName}</h6>
                                        <h6 className="text-medium">Membership No: {user.memberShipNo}</h6>
                                    </Col>
                                    <Col md={5}>
                                        <h6 className="text-medium">Wallet Balance: ₹{walletBalance}</h6>
                                        <h6 className="text-medium">Valid Upto: {user.validUpTo}</h6>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                {!addAmount ? (
                                    <>
                                        <div className="ms-5 mb-2">
                                            <div className="fw-semibold fs-6">
                                                Debit Amount: ₹{data[0].totalAmount} /-
                                            </div>
                                            <div className="fw-semibold fs-6">
                                                Payment Towards: Event And Seminar Wing
                                            </div>
                                        </div>
                                        <Card className="p-2 mb-3">
                                            <Card.Text className="fs-5 mb-0">Informative Note:</Card.Text>
                                            <ul>
                                                <li>Initiate wallet payments only from the company&#39;s registered and authorized bank account.</li>
                                                <li>The beneficiary Account displayed on this platform is unique to you only and should not be shared with anyone else.</li>
                                                <li>In Case of Rejection, Amount will be Credited to your wallet.</li>
                                                <li>You can monitor Transaction entries in your wallet.</li>
                                                <li>For Any Assistance, contact Chamber Desk.</li>
                                            </ul>
                                        </Card>
                                        <div className="text-center">
                                            {data[0].totalAmount > 0 ? (
                                                walletBalance < data[0].totalAmount ? (
                                                    <Button
                                                        variant="success"
                                                        className="rounded-5 px-5 text-medium"
                                                        onClick={() => setAddAmount(true)}
                                                    >
                                                        Add Balance
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="success"
                                                        className="rounded-5 px-5 text-medium"
                                                        onClick={handleConfirmPayment}
                                                    >
                                                        CONFIRM PAYMENT
                                                    </Button>
                                                )
                                            ) : (
                                                <Button
                                                    variant="success"
                                                    className="rounded-5 px-5 text-medium"
                                                    onClick={handleConfirmPayment}
                                                >
                                                    CONFIRM PAYMENT
                                                </Button>
                                            )}
                                        </div>

                                    </>
                                ) : (
                                    // Add Amount Form
                                    <Form className="mb-3" onSubmit={handleAddFund}>
                                        <div className="mb-3 d-flex justify-content-between">
                                            <h5>Add Amount</h5>
                                            <a href="#" onClick={() => navigate('/wallet')}>Show History</a>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h6>UTR NO.</h6>
                                            <Form.Control
                                                className="w-75"
                                                type="text"
                                                placeholder="Enter Utr No."
                                                required
                                                value={formData.utrNo}
                                                onChange={(e) =>
                                                    handleInputChange("utrNo", e.target.value)
                                                }
                                                maxLength={25}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h6>AMOUNT</h6>
                                            <Form.Control
                                                className="w-75"
                                                type="text"
                                                maxLength={6}
                                                placeholder="Enter Amount"
                                                required
                                                value={data[0].totalAmount}
                                                disabled // Disable input when adding funds
                                            />
                                        </div>
                                        <div className="d-flex align-items-center mb-3">
                                            <h6 className="me-3">PAYMENT Screenshot (MAX 3MB)</h6>
                                            <div className="me-3">
                                                <label
                                                    className={
                                                        formData.paymentScreenShot
                                                            ? "btn btn-success"
                                                            : "btn btn-primary"
                                                    }
                                                    htmlFor="paymentScreenShot"
                                                >
                                                    {formData.paymentScreenShot
                                                        ? "Uploaded"
                                                        : "Choose File"}
                                                </label>
                                                <input
                                                    style={{ display: "none" }}
                                                    type="file"
                                                    accept="image/*"
                                                    name="paymentScreenShot"
                                                    id="paymentScreenShot"
                                                    onChange={(e) =>
                                                        handleFileUpload("paymentScreenShot", e.target.files[0])
                                                    }
                                                />
                                            </div>
                                            {formData.paymentScreenShot && (
                                                <a
                                                    href={URL.createObjectURL(formData.paymentScreenShot)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <BsEye className="icon-size" />
                                                </a>
                                            )}
                                        </div>
                                        <div className="text-center">
                                            <Button
                                                variant="success"
                                                className="px-5"
                                                type="submit"
                                                disabled={isLoading}
                                            >
                                                {isLoading ? `loading...` : `Proceed Payment`}
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}