import axios from "axios";
import React, { useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { FaInfoCircle } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { MdOutlineEmail } from "react-icons/md";
import eCOImg from "../../assets/eCOVerification.jpeg";
import Layout from "../../components/Layouts/Layouts/Layout";
import EPlatformHeader from "../../components/basic/EPlatformHeader";
import ModalComponent from "../../components/basic/ModalComponent";
import { getURLbyEndPoint } from "../../store/api";

const EcoVerification = () => {
  // Initialize state for responseData
  const [responseData, setResponseData] = useState(null);

  const [email, setEmail] = useState("");
  const [authority, setAuthority] = useState("");
  const [typeOfVer, setTypeOfVer] = useState("");
  const [modeOfSub, setModOfSub] = useState("");
  const [fromFlag, setFormFlag] = useState(false);

  const [certOrigin, setCertOrigin] = useState(false);
  const [certMemb, setCertMemb] = useState(false);
  const [certCollab, setCertCollab] = useState(false);
  const [certAff, setCertAff] = useState(false);
  const [visa, setVisa] = useState(false);

  const handleTypeOfVer = (event) => {
    const selectedValue = event.target.value;
    setTypeOfVer(selectedValue === "Select a mode" ? "" : selectedValue);
  };

  // const handleModeChange = (event) => {
  //   const selectedValue = event.target.value;
  //   setModOfSub(selectedValue === "Select a mode" ? "" : selectedValue);
  // };

  const [coRefNo, setCoRefNo] = useState("");
  const handleCertOrigin = async () => {
    //----------for chamber desk manual---------------
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert("Please enter a valid email address");
      return;
    }
    if (!authority) {
      alert("Please enter Authority");
      return;
    }
    // if (!modeOfSub) {
    //   alert("Please enter Mode of Submission");
    //   return;
    // }
    if (!coRefNo) {
      alert("Please enter Refrence No.");
      return;
    }

    const modeOfSub = coRefNo.startsWith("eNSD/")
      ? "Non Standard (NSD)"
      : coRefNo.startsWith("eCO/") && typeOfVer === "Certificate of Origin (NP) / Trade Document"
        ? "Digital eCo (NP)"
        : coRefNo.startsWith("eCO/") && typeOfVer === "Supporting Document / Shipping Document"
          ? "Shipping Document"
          : "Manual COO (NP)";

    try {
      const res = await axios.post(getURLbyEndPoint("getCertOfOrigin"), {
        email,
        authority,
        coRefNo,
        modeOfSub,
      });
      const data = res.data.data;

      if (res.data.status) {
        setResponseData(data);
        setModOfSub(data.modeOfSub);

        setFormFlag(true);
        setCertOrigin(true);
      }
      alert(res.data.message);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        if (error.response.data.message) {
          alert(error.response.data.message);
        } else {
          alert("Server not responding");
        }
      } else {
      }
    }
  };

  const [membNo, setMembNo] = useState("");
  const handleCertMemb = async () => {
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert("Please enter a valid email address");
      return;
    }
    if (!membNo) {
      alert("Please enter membership No.");
      return;
    }

    try {
      const res = await axios.post(getURLbyEndPoint("getVerMembCertStatus"), {
        membNo,
        email,
      });
      const data = res.data.data;

      if (res.data.status) {
        setResponseData(data);

        setFormFlag(true);
        setCertMemb(true);
      }
      alert(res.data.message);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        alert(error.response.data.message);
      } else {
        alert("Server not responding");
      }
    }
  };

  const handleCertCollab = async () => {
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert("Please enter a valid email address");
      return;
    }
    if (!membNo) {
      alert("Please enter membership No.");
      return;
    }

    try {
      const res = await axios.post(getURLbyEndPoint("getVerMembCertStatus"), {
        membNo,
        email,
      });
      const data = res.data.data;

      if (res.data.status) {
        setResponseData(data);

        setFormFlag(true);
        setCertCollab(true);
      }
      alert(res.data.message);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        alert(error.response.data.message);
      } else {
        alert("Server not responding");
      }
    }
  };

  const handleCertAff = () => {
    alert("Not yet started.");
    // setFormFlag(true);
    // setCertAff(true);
  };

  const [refNo, setRefNo] = useState("");

  const handleVisa = async () => {
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert("Please enter a valid email address");
      return;
    }

    if (!refNo) {
      alert("Please enter Reference No.");
      return;
    }

    try {
      const res = await axios.post(getURLbyEndPoint("getVerVisaStatus"), {
        email,
        refNo,
      });
      const data = res.data.data;

      if (res.data.status) {
        setResponseData(data);

        setFormFlag(true);
        setVisa(true);
      }
      alert(res.data.message);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        alert(error.response.data.message);
      } else {
        alert("Server not responding");
      }
    }
  };

  //all api calls for selective forms

  // -----------------Certificate of Membership -------------------
  // --------------------------------------------------------------
  // --------------------------------------------------------------

  return (
    <Layout header="true">
      <EPlatformHeader
        heading="Document Verification"
        text="Verify your certificates and shipping documents hassle-free on this portal. Simply enter the required fields to access details instantly."
      />

      <div className="d-flex justify-content-center align-items-start mt-3">
        <div className="container py-1 align-items-center justify-content-center main-width">
          <span>
            Verify your membership certificate, affiliation certificate, and
            certificate of origin below. This platform offers global
            verification, ensuring the authenticity of shipping documents and
            certificates, highlighting the significance of document delivery and
            recognition by Chambers and other authorities.
          </span>
          <div className="d-flex gap-5 mt-3">
            <Container>
              <Row>
                <Col sm="4">
                  {" "}
                  <div style={{ width: "100%" }}>
                    <img src={eCOImg} className="img-fluid" alt="" srcset="" />
                  </div>
                </Col>
                <Col sm="8">
                  {fromFlag ? (
                    <div className="w-100">
                      {certOrigin && (
                        <div className="w-100">
                          <Table striped bordered hover>
                            <thead>
                              <tr className="text-center">
                                <th colSpan={2}>
                                  {modeOfSub === "Non Standard (NSD)"
                                    ? "VERIFICATION OF TRADE DOCUMENT"
                                    : modeOfSub === "Shipping Document"
                                      ? "Verification - SHIPPING DOCUMENT"
                                      : "VERIFICATION - Certificate of Origin (NP)"}
                                </th>
                              </tr>
                            </thead>

                            {modeOfSub === "Non Standard (NSD)" ? (
                              <tbody>
                                <tr>
                                  <td style={{ width: "50%" }}>
                                    {" "}
                                    <div>
                                      <h6>
                                        Application submitted Date:
                                        <span className="text-secondary">
                                          {responseData.appSubDate}
                                        </span>
                                      </h6>
                                      <h6>
                                        Officer Name :{" "}
                                        <span className="text-secondary">
                                          {responseData.officerName}
                                        </span>
                                      </h6>
                                      <h6>
                                        Type of Document: {" "}
                                        <span className="text-secondary">
                                          {responseData.documentType}
                                        </span>
                                      </h6>
                                      <h6 className="mt-4">
                                        Country: {" "}
                                        <span className="text-secondary">
                                          {responseData.country}
                                        </span>
                                      </h6>
                                    </div>
                                  </td>
                                  <td style={{ width: "50%" }}>
                                    <div>
                                      <h6>
                                        Ref No. :{" "}
                                        <span className="text-secondary">
                                          {responseData.refNo}
                                        </span>
                                      </h6>
                                      <h6>
                                        Date of Stamp :{" "}
                                        <span className="text-secondary">
                                          {responseData.dateOfStamp}
                                        </span>
                                      </h6>
                                      <h6>
                                        Application submitted to: {" "}
                                        <span className="text-secondary">
                                          AECCI- NAVI MUMBAI, INDIA.
                                        </span>
                                      </h6>
                                      <h6>
                                        Status :{" "}
                                        <span className="text-success">
                                          Verified
                                        </span>
                                      </h6>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={2}>
                                    <h6 className="text-center">
                                      Action :{" "}
                                      <span className="text-success">
                                        Certificate Approved
                                      </span>
                                    </h6>
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td>
                                    {" "}
                                    <div>
                                      <h6>
                                        Application submitted Date:
                                        <span className="text-secondary">
                                          {responseData.appSubDate}
                                        </span>
                                      </h6>
                                      <h6>
                                        Officer Name :{" "}
                                        <span className="text-secondary">
                                          {responseData.officerName}
                                        </span>
                                      </h6>
                                      <h6>
                                        FTA/PTA: {" "}
                                        <span className="text-secondary">
                                          Non Preferential
                                        </span>
                                      </h6>
                                      <h6>
                                        Action :{" "}
                                        <span className="text-success">
                                          Certificate Approved
                                        </span>
                                      </h6>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <h6>
                                        COO Ref No. :{" "}
                                        <span className="text-secondary">
                                          {responseData.refNo}
                                        </span>
                                      </h6>
                                      <h6>
                                        Date of Stamp :{" "}
                                        <span className="text-secondary">
                                          {responseData.dateOfStamp}
                                        </span>
                                      </h6>
                                      <h6>
                                        Application submitted to: {" "}
                                        <span className="text-secondary">
                                          AECCI- NAVI MUMBAI, INDIA.
                                        </span>
                                      </h6>
                                      <h6>
                                        Status :{" "}
                                        <span className="text-success">
                                          Verified
                                        </span>
                                      </h6>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </Table>
                        </div>
                      )}
                      {certMemb && (
                        <>
                          <div className="w-100">
                            <Table striped bordered hover>
                              <thead>
                                <tr className="text-center">
                                  <th colSpan={2}>
                                    {console.log("here", responseData)}
                                    VERIFICATION - Certificate of Membership
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="fw-semibold">Company Name</td>
                                  <td>
                                    {responseData && responseData.companyName
                                      ? responseData.companyName
                                      : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-semibold">
                                    Membership No.
                                  </td>
                                  <td>
                                    {responseData && responseData.memberShipNo
                                      ? responseData.memberShipNo
                                      : ""}
                                  </td>
                                </tr>{" "}
                                <tr>
                                  <td className="fw-semibold">Valid Upto </td>
                                  <td>
                                    {responseData && responseData.validUpTo
                                      ? responseData.validUpTo
                                      : ""}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="fw-semibold">Status</td>
                                  <td className=" text-success fw-semibold">
                                    Verified{" "}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </>
                      )}
                      {certCollab && (
                        <>
                          <div className="w-100">
                            <Table striped bordered hover>
                              <thead>
                                <tr className="text-center">
                                  <th colSpan={2}>
                                    {console.log("here", responseData)}
                                    AECCI B2B Event invitation Verification
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="fw-semibold text-nowrap">
                                    Organization Name
                                  </td>
                                  <td>
                                    {responseData && responseData.companyName
                                      ? responseData.companyName
                                      : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-semibold">
                                    Collaboration No.
                                  </td>
                                  <td>
                                    {responseData && responseData.memberShipNo
                                      ? responseData.memberShipNo
                                      : ""}
                                  </td>
                                </tr>{" "}
                                <tr>
                                  <td className="fw-semibold">Collaboration Valid Upto </td>
                                  <td>
                                    {responseData && responseData.validUpTo
                                      ? responseData.validUpTo
                                      : ""}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="fw-semibold text-nowrap">
                                    Invitation Status
                                  </td>
                                  <td
                                    className={
                                      responseData && responseData.isApproved
                                        ? "text-success"
                                        : "text-primary"
                                    }
                                  >
                                    {responseData && responseData.isApproved
                                      ? responseData.isApproved
                                      : "Processing"}
                                  </td>
                                </tr>{" "}
                                <tr>
                                  {" "}
                                  <td className="fw-semibold">
                                    Delegate Details
                                  </td>
                                  <td>
                                    {responseData &&
                                      responseData.passports &&
                                      responseData.passports.length > 0 ? (
                                      responseData.passports.map(
                                        (passport, index) => (
                                          <div key={index} className="mb-2">
                                            <div>
                                              <strong>
                                                Delegate {index + 1}
                                              </strong>
                                            </div>
                                            <div>
                                              Name: {passport.firstName}{" "}
                                              {passport.lastName}
                                            </div>
                                            <div>
                                              Passport No: {passport.passportNo}
                                            </div>
                                            <div>
                                              Designation:{" "}
                                              {passport.designation}
                                            </div>
                                            <div>
                                              <a
                                                href={passport.passportCopy}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                View Passport Copy
                                              </a>
                                            </div>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <span>
                                        No delegate details available.
                                      </span>
                                    )}
                                  </td>
                                </tr>{" "}
                                <tr>
                                  <td className="fw-semibold">Status</td>
                                  <td className=" text-success fw-semibold">
                                    Verified{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="fw-semibold"
                                    colSpan="2"
                                    style={{
                                      backgroundColor: "#f5f9ff",
                                      fontSize: "16px",
                                      color: "#0d6efd",
                                      borderRadius: "8px",
                                      padding: "15px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Note: Embassies, Consulates, Airport Customs; for any further information
                                    or clarification, please reach to
                                    Adv. Rohini P.K. at
                                    <strong> 0091-7907312582 </strong> during
                                    <strong>
                                      {" "}
                                      4:30 AM to 1:30 PM
                                    </strong>{" "}<br />
                                    (UTC time zone).
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </>
                      )}
                      {certAff && (
                        <>
                          <div className="w-75">
                            <Table striped bordered hover>
                              <thead>
                                <tr className="text-center">
                                  <th colSpan={2}>
                                    VERIFICATION - Certificate of Affiiation
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {" "}
                                    <div>
                                      <h6>Name </h6>
                                      <h6>Affiliation No. </h6>
                                      <h6>Validity </h6>
                                      <h6>Status </h6>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <h6>Salamon & co </h6>
                                      <h6>APE2324-001</h6>
                                      <h6>30/01/2024</h6>
                                      <h6 className="text-success">Verified</h6>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </>
                      )}
                      {visa && (
                        <>
                          <div className="w-75">
                            <Table striped bordered hover>
                              <thead>
                                <tr className="text-center">
                                  <th colSpan={2}>
                                    VERIFICATION - Visa Recommendation Letter
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="fw-semibold">Ref No.</td>
                                  <td>{responseData.documentNo}</td>
                                </tr>
                                <tr>
                                  <td className="fw-semibold">Issued Date</td>
                                  <td>{responseData.dateOfIssue}</td>
                                </tr>
                                <tr>
                                  <td className="fw-semibold">Issued For</td>
                                  <td>{responseData.companyName}</td>
                                </tr>
                                <tr>
                                  <td className="fw-semibold">Addressed to</td>
                                  <td>{responseData.addressTo}</td>
                                </tr>
                                <tr>
                                  <td className="fw-semibold">Country</td>
                                  <td>{responseData.country}</td>
                                </tr>
                                <tr>
                                  <td className="fw-semibold">
                                    Name of Applicant
                                  </td>
                                  <td>
                                    {responseData.title}{" "}
                                    {responseData.givenName}{" "}
                                    {responseData.surname}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-semibold">Purpose</td>
                                  <td> {responseData.purposeOfVisit}</td>
                                </tr>
                                <tr>
                                  <td className="fw-semibold">Status</td>
                                  <td className="text-success fw-semibold">
                                    Verified
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </>
                      )}
                      <a href="#" onClick={() => window.location.reload()}>
                        <h6>reset →</h6>
                      </a>{" "}
                    </div>
                  ) : (
                    <div className="w-100">
                      <Form.Group className="d-flex flex-row align-items-center justify-content-between mb-4">
                        <Form.Label as="h5">
                          Email ID<span className="text-danger">*</span>
                          <ModalComponent body={<EmailTip />} />
                        </Form.Label>
                        <Form.Control
                          type="email"
                          style={{ width: "65%" }}
                          placeholder="Enter email address"
                          maxLength={50}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="d-flex flex-row align-items-center justify-content-between mb-4">
                        <Form.Label
                          as="h5"
                          className="me-3"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Type of Verification
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          defaultValue="Choose..."
                          onChange={handleTypeOfVer}
                          value={typeOfVer}
                          style={{ width: "65%" }}
                        >
                          <option>Select a mode</option>
                          <option>
                            Certificate of Origin (NP) / Trade Document
                          </option>
                          <option>
                            Supporting Document / Shipping Document
                          </option>
                          <option>Certificate of Membership</option>
                          <option>
                            Certificate of Collaboration / Invitation
                          </option>
                          <option>Certificate of Affiliation</option>
                          <option>Visa Recommendation Letter</option>
                        </Form.Select>
                      </Form.Group>
                      {/* -----------------------------------
                ---------------------------------------
                certificate of orgin setup--------------
                ----------------------------------- */}
                      <div>
                        {(typeOfVer ===
                          "Certificate of Origin (NP) / Trade Document" || typeOfVer ===
                          "Supporting Document / Shipping Document") && (
                            <>
                              {/* <Form.Group className="d-flex flex-row align-items-center justify-content-between mb-4">
                              <Form.Label
                                as="h5"
                                className="me-3"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Mode of Submission<span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                defaultValue="Choose..."
                                onChange={handleModeChange}
                                value={modeOfSub}
                                style={{ width: "65%" }}
                              >
                                <option>Select a mode</option>
                                <option>Manual COO (NP)</option>
                                <option>Digital eCo (NP)</option>
                                <option>Non Standard (NSD)</option>
                              </Form.Select>
                            </Form.Group> */}

                              <Form.Group className="d-flex flex-row align-items-center justify-content-between mb-4">
                                <Form.Label
                                  as="h5"
                                  className="me-3"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  Authority<span className="text-danger">*</span>
                                  <ModalComponent body={<AuthorityTip />} />
                                </Form.Label>
                                <Form.Select
                                  value={authority}
                                  onChange={(e) => setAuthority(e.target.value)}
                                  style={{ width: "65%" }}
                                >
                                  <option>Select an authority</option>
                                  <option>Embassy</option>
                                  <option>Consulate</option>
                                  <option>High Commission</option>
                                  <option>Exporter</option>
                                  <option>Importer</option>
                                  <option>Customs</option>
                                  <option>Custom House Agent</option>
                                  <option>
                                    Freight Forwarder/ Shipping & Logistics
                                  </option>
                                </Form.Select>
                              </Form.Group>
                            </>
                          )}
                        {(typeOfVer ===
                          "Certificate of Origin (NP) / Trade Document" || typeOfVer ===
                          "Supporting Document / Shipping Document") && (
                            <>
                              <Form.Group className="d-flex flex-row align-items-center justify-content-between mb-4">
                                <Form.Label as="h5">
                                  {/* {modeOfSub === "Digital eCo (NP)"
                            ? "eCo Reference No:"
                            : " COO Refrence No:"} */}
                                  Reference No
                                  <span className="text-danger">*</span>:
                                  <ModalComponent body={<RefNoTip />} />
                                  {/* {modeOfSub === "Digital eCo (NP)"
                                    ? "eCo Reference No:"
                                    : modeOfSub === "Manual COO (NP)"
                                    ? "COO Reference No:"
                                    : modeOfSub === "Non Standard (NSD)"
                                    ? "Reference No"
                                    : "Reference No:"} */}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  style={{ width: "65%" }}
                                  // placeholder={
                                  //   modeOfSub === "Digital eCo (NP)"
                                  //     ? "For eg: eCo/12345/23-24"
                                  //     : modeOfSub === "Manual COO (NP)"
                                  //     ? "For eg: 12345/24"
                                  //     : modeOfSub === "Non Standard (NSD)"
                                  //     ? "For eg:	eNSD/123/24-25"
                                  //     : "For eg: eCo/12345/23-24"
                                  // }
                                  maxLength={20}
                                  value={coRefNo}
                                  onChange={(e) => setCoRefNo(e.target.value)}
                                />
                              </Form.Group>
                              <Button
                                variant="success"
                                onClick={handleCertOrigin}
                              >
                                VERIFY DOCUMENT
                              </Button>{" "}
                            </>
                          )}
                      </div>
                      {/* -----------------------------------
                ---------------------------------------
                certificate of Membership--------------
                ----------------------------------- */}
                      <div>
                        {typeOfVer === "Certificate of Membership" && (
                          <>
                            <Form.Group className="d-flex flex-row align-items-center justify-content-between mb-4">
                              <Form.Label
                                as="h5"
                                className="me-3"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Membership No.
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="For e.g. : CORP2324-990"
                                style={{ width: "65%" }}
                                maxLength={20}
                                value={membNo}
                                onChange={(e) => setMembNo(e.target.value)}
                              />
                            </Form.Group>
                            <Button
                              variant="success"
                              disabled={!membNo}
                              onClick={handleCertMemb}
                            >
                              VERIFY DOCUMENT
                            </Button>
                          </>
                        )}
                      </div>

                      <div>
                        {typeOfVer ===
                          "Certificate of Collaboration / Invitation" && (
                            <>
                              <Form.Group className="d-flex flex-row align-items-center justify-content-between mb-4">
                                <Form.Label
                                  as="h5"
                                  className="me-3"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  Collaboration No.
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="For e.g. : AECCI/COLL/24-25/123"
                                  style={{ width: "65%" }}
                                  maxLength={25}
                                  value={membNo}
                                  onChange={(e) => setMembNo(e.target.value)}
                                />
                              </Form.Group>
                              <Button
                                variant="success"
                                disabled={!membNo}
                                onClick={handleCertCollab}
                              >
                                VERIFY DOCUMENT
                              </Button>
                            </>
                          )}
                      </div>

                      {/* -----------------------------------
                ---------------------------------------
                certificate of Affiliation--------------
                ----------------------------------- */}
                      <div>
                        {typeOfVer === "Certificate of Affiliation" && (
                          <>
                            <Form.Group className="d-flex flex-row align-items-center justify-content-between mb-4">
                              <Form.Label
                                as="h5"
                                className="me-3"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Affiliation No.
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="For e.g. : APE2324-990"
                                style={{ width: "65%" }}
                              />
                            </Form.Group>
                            <Button variant="success" onClick={handleCertAff}>
                              VERIFY DOCUMENT
                            </Button>
                          </>
                        )}
                      </div>
                      {/* -----------------------------------
               ---------------------------------------
               visa Recommendation Letter--------------
               ----------------------------------- */}
                      <div>
                        {typeOfVer === "Visa Recommendation Letter" && (
                          <>
                            <Form.Group className="d-flex flex-row align-items-center justify-content-between mb-4">
                              <Form.Label as="h5" className="me-3">
                                Recommendation Letter <br />
                                Refrence No.
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="For e.g. : 12345/24"
                                value={refNo}
                                onChange={(e) => setRefNo(e.target.value)}
                                style={{ width: "65%" }}
                              />
                            </Form.Group>
                            <Button
                              variant="success"
                              disabled={!refNo}
                              onClick={handleVisa}
                            >
                              VERIFY DOCUMENT
                            </Button>
                          </>
                        )}
                      </div>

                      <Card className=" my-3">
                        <Card.Body>
                          <Card.Title className="d-flex">
                            <FaInfoCircle />
                            <h5 className="ms-2">INFORMATIVE NOTES</h5>
                          </Card.Title>
                          <Card.Text>
                            <ul>
                              <li>
                                The verification details is effective from 1st
                                march 2024.
                              </li>
                              <li>
                                Verification Details shall be updated on the
                                platform within 48 hours of issuance.
                              </li>
                              <li>
                                For any identified errors or omissions in the
                                provided details, please reach out to the
                                Chamber Desk promptly.
                              </li>
                              <li>
                                The Data is maintained by Team AECCI to ensure
                                accuracy and reliability.
                              </li>
                              <li>
                                For more specific information, please send your
                                inquiries via email to docverify@aecci.org.in.
                              </li>
                            </ul>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EcoVerification;

function RefNoTip() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">Reference No Tip:</h5>
        </div>
        <p>
          {" "}
          {/* <strong>There are three types of Trade Documents:</strong> */}
        </p>
        <ul className="text-small">
          <li>
            Each Document Attested by chamber has a "Reference No" Generated.
          </li>
          <li>
            To Verify the details, User must enter the Ref No. mentioned in the
            document
          </li>
          <li>
            The format of reference no. are as follows. (user may enter any one
            of them as per the format and no mention in their document)
          </li>
          <ul>
            <li>eCO/001/24-25</li>
            <li>1234/24</li>
            <li>eNSD/001/24-25</li>
          </ul>
          <div className="mt-4 text-center">
            For any assistance contact chamber desk at <br />
            <IoIosCall />
            022-41271145/46/47 or email us at <MdOutlineEmail />
            <span className="text-primary">docverify@aecci.org.in</span>
          </div>
        </ul>
      </div>
    </>
  );
}

function AuthorityTip() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">Authority Tip:</h5>
        </div>
        <p>
          {" "}
          {/* <strong>There are three types of Trade Documents:</strong> */}
        </p>
        <ul className="text-small">
          <li>
            Selection of the Authority must be on the basis of who is Verifying
            it.
          </li>
          <li>
            if the Embassy is verifying the details, then select "Embassy" from
            the dropdown
          </li>
        </ul>
      </div>
    </>
  );
}

function EmailTip() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">Email Tip</h5>
        </div>
        <ul className="text-small">
          <li>
            Enter the email address of the authority who is verifying the
            details. wether Embassy, buyer, Custom, etc.
          </li>
        </ul>
      </div>
    </>
  );
}
