import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { IoClose } from 'react-icons/io5';
import DgftTamp from '../../assets/popupBanners/DgftTamp.png';
import { Link } from 'react-router-dom';

const NoticePopup = ({ isOpen, toggle }) => {


  return (
    <Modal size="lg" show={isOpen} onHide={toggle} centered>
        <Link to="/trade-document" style={{ textDecoration: 'none' }}>
      <Modal.Body
        className="p-4 cursor-pointer"
      >
        <div className="text-center position-relative">
          {/* Close Button */}
          <IoClose
            className="text-danger position-absolute"
            style={{ top: '0px', right: '0px', fontSize: '35px', cursor: 'pointer' }}
            onClick={toggle}
          />
          {/* Warning Image */}
          <img
        src={DgftTamp}
        alt="Warning Animation"
        style={{
          maxWidth: '100%', // Ensures the image does not exceed modal width
          maxHeight: '80vh', // Ensures the image does not exceed 80% of viewport height
          width: 'auto', // Maintains aspect ratio
          height: 'auto', // Maintains aspect ratio
        }}
      />

{/* <img
            src={warningGif}
            alt="Warning Animation"
            width="160"
            style={{ maxWidth: '100%', height: 'auto', }}
          /> */}

          {/* Alert Title */}
          {/* <h3 className="fw-bold text-danger mb-3">URGENT NOTICE</h3> */}

          {/* Notification Content */}
          {/* <div className="mx-auto">
            <p className="text-dark fw-semibold fs-5 mb-3">
              Please be informed that, due to DGFT Trade Notice No.{' '}
              <a
                href="https://www.aecci.org.in/wp-content/uploads/2024/12/Rescheduling-of-Launch-Date-for-Preferential-eCoO-2.0-System-reg.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="fw-bold text-danger text-decoration-underline"
              >24/20.12.2024</a>, <br /> the AECCI e-Platform will be unavailable for COO
              services.
            </p>
            <p className="text-dark fw-semibold fs-5 mb-3">
              Effective <span className="fw-bold">01.01.2025</span>, applicants seeking the Certificate of Origin <br />(Non-Preferential) must apply through the DGFT Trade Connect platform. <br />Please refer to the notice in this regard.</p>
            <p className="text-primary fw-semibold fs-5 mb-3">
              When applying, ensure that you select{' '}<span className="fw-bold">Asian Exporters Chamber of Commerce & Industry</span> as the designated agency for processing the e-COO.</p>
            <p className="text-secondary fs-6">
              For any assistance or inquiries, kindly reach out to our 24/7 Chamber Helpdesk at <strong>022-41271145/46</strong>.<br /> We will keep you updated with any further changes in this regard. Thank you.
            </p>
          </div> */}
          
        </div>
      </Modal.Body>
      </Link>
    </Modal>
  );
};

export default NoticePopup;
