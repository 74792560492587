import React, { useEffect, useState } from "react";
import { BsEye, BsFillInfoCircleFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import eCoTemplate from "../../../assets/coFormatTemplate.jpg";
import DgftRefEg from "../../../assets/CoImages/DgftRefEg.png";
import israeliStamp from "../../../assets/CoImages/IsraeilStamp.png";
import submitEco from "../../../assets/submitEco.png";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Layout from "../../../components/Layouts/Layouts/Layout";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import { getAuthUserlocal } from "../../../store/services";

import axios from "axios";
import jsPDF from "jspdf";
import {
  Button,
  Card,
  CloseButton,
  Col,
  Form,
  Modal,
  Nav, NavItem, NavLink,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from "react-bootstrap";
import { IoWarning } from "react-icons/io5";
import Logo from "../../../assets/footerLogo.png";
import AlertPopup from "../../../components/basic/AlertPopup";
import Contactinfo from "../../../components/basic/Contactinfo";
import DemoModal from "../../../components/basic/DemoModal";
import ModalComponent, {
  PopUpModal
} from "../../../components/basic/ModalComponent";
import { numberToWords } from "../../../components/basic/numberToWords";
import QuickLinks from "../../../components/basic/QuickLinks";
import ManualCo from "../../../components/servicesComponents/ManualCo";
import NonStandDoc from "../../../components/servicesComponents/NonStandDoc";
import DraftTrack from "../../../components/trackDoc/draftTrack";
import ECoDataTrack from "../../../components/trackDoc/eCoTrack";
import McoDataTrack from "../../../components/trackDoc/mcoTrack";
import NonStandTrack from "../../../components/trackDoc/nonStandTrack";
import { validateAmount } from "../../../components/Validations/lowBalanceValidation";
import { getURLbyEndPoint } from "../../../store/api";

// const getAuthUserlocal() = getAuthUserlocal();
const priceControl = getAuthUserlocal()?.priceControl;
const user = getAuthUserlocal();
const isTrial = getAuthUserlocal()?.isTrial
  ? getAuthUserlocal().isTrial
  : false;
const ECO = () => {
  const userId = getAuthUserlocal?.ecoUserId;
  const priceControl = getAuthUserlocal()?.priceControl;

  const [modalShow, setModalShow] = useState(false);
  const [dgftModalShow, setDgftModalShow] = useState(false);
  const [activePage, setActivePage] = useState("homePage");
  const [eCoCreated, setECoCreated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitDraft, setIsSubmitDraft] = useState(false);
  const [apiSubmitEco, setApiSubmitEco] = useState(false);
  const [apiSubmitEcoDraft, setApiSubmitEcoDraft] = useState(false);
  const [activeTable, setActiveTable] = useState("eco");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [draftName, setDraftName] = useState("");
  const [showAlert, setShowAlert] = useState(false)

  const [showDemo, setShowDemo] = useState(false);
  const handleCloseDemo = () => setShowDemo(false);
  const handleShowDemo = () => setShowDemo(true);


  const toggleAlertModal = () => {
    setShowAlert(!showAlert)
  }

  const handleSelect = (eventKey) => {
    setActiveTable(eventKey);
  };

  //states setup and all api setup----------------------------------------------------------------------------------------
  //   -------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------
  //   -------------------------------------------------------------------------------------------------------------------

  const intialExtendedData = {
    itemNo: "",
    noOfPack: "",
    desOfGoods: "",
    originCriteria: "",
    weightAndQuantity: "",
    noAndDateInvoice: "",
  };

  const initialFormData = {
    eCoDetails: {
      consignedFrom: "",
      consignedTo: "",
      meanOfTran: "",
      extendedData: [intialExtendedData],
      israeliDec: "false",
      orginOfCom: "INDIA",
      designation: "",
      impCountry: "",
      forComRegion: "",
      place: "",
      date: "",
      stamp: null,
    },


    //Dgft
    orginOfCom: "INDIA",
    orginOfComFlag: false,
    israeliDec: "false",
    impCountry: "",
    importingCountry: "",
    forComRegion: "",
    dgfteCoFile: null,
    dgftRefNo: "",


    eCoFile: null,
    comInvoice: null,
    packingList: null,
    addDoc1: null,
    addDoc2: null,
    refDoc: null,
    receipt: null,
    totalAmt: 0,

    dgfteCoFileFlag: false,

    dgfteCoDetailsFlag: false,

    eCoFileFlag: false,
    comInvoiceFlag: false,
    packingListFlag: false,
    addDoc1Flag: false,
    addDoc2Flag: false,
    refDocFlag: false,
  };

  const [formData, setFormData] = useState(initialFormData);

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  const handleBack = () => {
    setActivePage("homePage");
  };

  //all function start here for eco----------------------------
  // ----------------------------------------------------------
  // ----------------------------------------------------------

  // adding extend page----------------------------------
  const handleAddEcoForm = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      eCoDetails: {
        ...prevFormData.eCoDetails,
        extendedData: [
          ...prevFormData.eCoDetails.extendedData,
          intialExtendedData,
        ],
      },
    }));
  };

  //simple file saving
  const handleFileChange = (event, fileName) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        if (file.size <= 5 * 1024 * 1024) {
          const updatedFormData = { ...formData, [fileName]: file };
          if (fileName === "dgfteCoFile") {
            updatedFormData.dgfteCoFileFlag = true;
          }
          setFormData(updatedFormData);
        } else {
          alert("File size exceeds 5MB. Please select a smaller file.");
        }
      } else {
        alert("Please select a PDF file.");
      }
    }
  };



  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  //addition diles setup
  const handleAddFileClick = (fileType) => {
    if (!formData.addDoc1Flag) {
      setFormData({
        ...formData,
        addDoc1Flag: true,
      });
    } else if (!formData.addDoc2Flag) {
      setFormData({
        ...formData,
        addDoc2Flag: true,
      });
    }
  };

  //calculating total amount
  const calculateTotalAmt = () => {
    let total = 0;

    if (formData.eCoFileFlag) {
      total += priceControl?.eco;
    }
    if (formData.dgfteCoFileFlag) {
      total += priceControl?.dgft;
    }
    if (formData.comInvoiceFlag) {
      total += priceControl?.comInvoiceEco;
    }
    if (formData.packingListFlag) {
      total += priceControl?.packListEco;
    }
    if (formData.addDoc1Flag) {
      total += priceControl?.addDocEco;
    }
    if (formData.addDoc2Flag) {
      total += priceControl?.addDocEco;
    }

    //for per set(Processing charges)
    total += priceControl?.ecoPc;

    setFormData((prevFormData) => ({
      ...prevFormData,
      totalAmt: total,
    }));
  };

  // ------------------------------------------------
  // ------------------------------------------------
  // ---------------simple functions setups----------
  // ------------------------------------------------
  // Function to handle viewing a document
  const viewDocument = (documentId) => {
    const documentFile = formData[documentId];
    if (documentFile instanceof File || documentFile instanceof Blob) {
      const documentUrl = URL.createObjectURL(documentFile);
      window.open(documentUrl, "_blank");
    } else {
      console.log("Document not found or invalid");
    }
  };

  const editDocument = (documentId) => {
    document.getElementById(documentId).click();
  };

  const deleteDocument = (documentId) => {
    console.log(documentId);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [documentId]: null,
    }));
  };

  //creating co
  const handleCreateCo = async () => {
    const pdf = new jsPDF({
      unit: "mm",
      format: "a4",
      orientation: "portrait",
    });

    for (const [index, data] of formData.eCoDetails.extendedData.entries()) {
      // Set background image
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(eCoTemplate, "JPEG", 0, 0, imgWidth, imgHeight);

      pdf.setFontSize(9);
      pdf.setFont("helvetica");
      pdf.setTextColor(80, 80, 80);
      // pdf.text(15, 25, formData.eCoDetails.consignedFrom);

      //for consigned from value------------------
      // ---------------------------------------
      // ---------------------------------------
      const consignedFromWidth = 100;
      const consignedFromLine = pdf.splitTextToSize(
        formData.eCoDetails.consignedFrom,
        consignedFromWidth
      );
      let yPosConsignedFrom = 25;
      consignedFromLine.forEach((line) => {
        pdf.text(15, yPosConsignedFrom, line);
        yPosConsignedFrom += 4;
      });

      //for consigned to value------------------
      // ---------------------------------------
      // ---------------------------------------
      const consignedToWidth = 100;
      const consignedToLine = pdf.splitTextToSize(
        formData.eCoDetails.consignedTo,
        consignedFromWidth
      );
      let yPosConsignedTo = 55;
      consignedToLine.forEach((line) => {
        pdf.text(15, yPosConsignedTo, line);
        yPosConsignedTo += 4;
      });

      //for Mean of transport value------------------
      // ---------------------------------------
      // ---------------------------------------
      const meanOfTranWidth = 100;
      const meanOfTranLine = pdf.splitTextToSize(
        formData.eCoDetails.meanOfTran,
        consignedFromWidth
      );
      let yPosmeanOfTran = 102;
      meanOfTranLine.forEach((line) => {
        pdf.text(15, yPosmeanOfTran, line);
        yPosmeanOfTran += 4;
      });

      //for Item NO value------------------
      // ---------------------------------------
      // ---------------------------------------
      const itemNoWidth = 8;
      const itemNoLine = pdf.splitTextToSize(data.itemNo, itemNoWidth);
      let yPositemNo = 122;
      itemNoLine.forEach((line) => {
        pdf.text(15, yPositemNo, line);
        yPositemNo += 4;
      });

      //for No of Pack value------------------
      // ---------------------------------------
      // ---------------------------------------
      const noOfPackWidth = 22;
      const noOfPackLine = pdf.splitTextToSize(data.noOfPack, noOfPackWidth);
      let yPosnoOfPack = 122;
      noOfPackLine.forEach((line) => {
        pdf.text(26, yPosnoOfPack, line);
        yPosnoOfPack += 4;
      });

      //for Description of goods value------------------
      // ---------------------------------------
      // ---------------------------------------
      const desOfGoodsWidth = 70;
      const desOfGoodsLine = pdf.splitTextToSize(
        data.desOfGoods,
        desOfGoodsWidth
      );
      let yPosdesOfGoods = 122;
      desOfGoodsLine.forEach((line) => {
        pdf.text(52, yPosdesOfGoods, line);
        yPosdesOfGoods += 4;
      });

      //for Orginal Criteria value------------------
      // ---------------------------------------
      // ---------------------------------------
      pdf.setFontSize(7);

      const originCriteriaWidth = 13;
      const originCriteriaLine = pdf.splitTextToSize(
        data.originCriteria,
        originCriteriaWidth
      );
      let yPosoriginCriteria = 122;
      originCriteriaLine.forEach((line) => {
        pdf.text(125, yPosoriginCriteria, line);
        yPosoriginCriteria += 4;
      });
      pdf.setFontSize(9);

      //for Weight and quantity value------------------
      // ---------------------------------------
      // ---------------------------------------
      const weightAndQuantityWidth = 20;
      const weightAndQuantityLine = pdf.splitTextToSize(
        data.weightAndQuantity,
        weightAndQuantityWidth
      );
      let yPosweightAndQuantity = 122;
      weightAndQuantityLine.forEach((line) => {
        pdf.text(140, yPosweightAndQuantity, line);
        yPosweightAndQuantity += 4;
      });

      //for no. and date invoice value------------------
      // ---------------------------------------
      // ---------------------------------------
      const noAndDateInvoiceWidth = 30;
      const noAndDateInvoiceLine = pdf.splitTextToSize(
        data.noAndDateInvoice,
        noAndDateInvoiceWidth
      );
      let yPosnoAndDateInvoice = 122;
      noAndDateInvoiceLine.forEach((line) => {
        pdf.text(165, yPosnoAndDateInvoice, line);
        yPosnoAndDateInvoice += 4;
      });

      //adding image in if israeli or not
      // Set background image
      if (
        formData.eCoDetails.israeliDec === "true" &&
        pdf.addImage(israeliStamp, "JPEG", 128, 196, 75, 40)
      )
        //adding this text for india and foreign origin

        pdf.setFontSize(8);
      const orginOfComWidth = 70;
      const originOfComText = `The undersigned hereby declares that the above details and statements are correct; that all the goods were produced in ${formData.eCoDetails.orginOfCom} and that they comply with the origin requirements.`;
      const orginOfComLine = pdf.splitTextToSize(
        originOfComText,
        orginOfComWidth
      );
      let yPosorginOfCom = 234;
      orginOfComLine.forEach((line) => {
        pdf.text(123, yPosorginOfCom, line);
        yPosorginOfCom += 4;
      });

      //for Importing Country value------------------
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(8);
      pdf.setTextColor(37, 35, 36);

      //customization for centering imorting country
      const validUptoTextWidth = pdf.getTextWidth(
        formData.eCoDetails.impCountry
      ); // Measure text width
      const validUptoCenterX = 164 - validUptoTextWidth / 2; // Calculate center X
      pdf.text(validUptoCenterX, 252, formData.eCoDetails.impCountry);

      // pdf.text(164, 252, formData.eCoDetails.impCountry);
      pdf.setTextColor(0, 0, 200);
      pdf.setFontSize(7);
      pdf.text(123, 259, `FOR ${formData.eCoDetails.forComRegion}`);
      pdf.setFontSize(8);
      pdf.text(123, 277, formData.eCoDetails.designation);
      pdf.setTextColor(0, 0, 0);

      //for STAMP value------------------
      const imgDataStamp = await readAsDataURL(formData.eCoDetails.stamp);
      pdf.addImage(imgDataStamp, "JPEG", 124, 260, 15, 15);

      //for place and value are adding here value------------------
      pdf.text(125, 282, `Place: ${formData.eCoDetails.place}`);
      pdf.text(170, 282, `Date: ${formData.eCoDetails.date}`);

      //adding page no.
      pdf.setFont("helvetica", "normal");
      pdf.text(
        122,
        225,
        `${index + 1}/${formData.eCoDetails.extendedData.length}`,
        { align: "right" }
      );

      //for more pages
      if (index < formData.eCoDetails.extendedData.length - 1) {
        pdf.addPage();
      }
    }
    // Generate Blob object
    const blobPdf = pdf.output("blob", { encoding: "raw" });

    // Convert Blob to File
    const eCoFile = new File([blobPdf], "eCoFile.pdf", {
      type: "application/pdf",
    });

    setFormData({
      ...formData,
      eCoFile: eCoFile,
    });
  };

  // //create eco here--------------------------------------------
  // --------------------------------------------------------------
  // --------------------------------------------------------------
  // --------------------------------------------------------------
  const handleCreateReceipt = async () => {
    // Create a new instance of jsPDF
    const pdf = new jsPDF();

    console.log(Logo);
    // Add logo image
    const pageWidth = pdf.internal.pageSize.getWidth();
    const xOfImg = (pageWidth - 25) / 2;
    pdf.addImage(Logo, "JPEG", xOfImg, 8, 25, 25);

    // Add content to the PDF using addText function
    pdf.setFontSize(20);
    pdf.text("Asian Exporters' Chamber of Commerce And Industry", 22, 45); // Adjusted position for title
    pdf.setFontSize(12);
    pdf.text(
      "Regd Office: 604, 6th Floor, Hilton Centre, Plot No.66, Sector-11, CBD Belapur, Navi Mumbai,",
      20,
      52
    );
    pdf.text("Maharashtra, E-mail: info@aecci.org.in", 68, 56);

    pdf.setFontSize(20);
    pdf.text("INVOICE CUM RECEIPT", 64, 74);
    pdf.line(15, 78, 195, 78); // horizontal line

    pdf.setFontSize(10);
    pdf.text("Receipt No:", 15, 82);
    pdf.text("Document No:", 15, 87);
    pdf.text(`Received From: ${getAuthUserlocal().companyName}`, 15, 92);
    pdf.text("Address: ", 15, 97);
    const address =
      getAuthUserlocal().address1 + " " + getAuthUserlocal().address2;
    const addressLines = pdf.splitTextToSize(address, 60);
    const addLineHeight = 5;
    addressLines.forEach((line, index) => {
      pdf.text(line, 30, 97 + index * addLineHeight);
    });

    //second line
    pdf.text("Date:", 130, 82);
    pdf.text("Time:", 130, 87);
    pdf.text(`AECCI Code: ${getAuthUserlocal().memberShipNo}`, 130, 92);
    pdf.text(`GSTIN: ${getAuthUserlocal().gstNo}`, 130, 97);
    pdf.line(15, 115, 195, 115); // horizontal line

    // Table
    const headers = ["Sr no.", "Particulars", "Quantity", "Rate", "Amount"];

    const data = [];
    if (formData.eCoFileFlag) {
      data.push([
        "1",
        "e-Co(NP)",
        "1",
        `Rs. ${priceControl?.eco}/-`,
        `Rs. ${priceControl?.eco}/-`,
      ]);
    }
    if (formData.comInvoiceFlag) {
      data.push([
        "2",
        "Commercial Invoice.",
        "1",
        `Rs. ${priceControl?.comInvoiceEco}/-`,
        `Rs. ${priceControl?.comInvoiceEco}/-`,
      ]);
    }
    if (formData.packingListFlag) {
      data.push([
        "3",
        "Packing List",
        "1",
        `Rs. ${priceControl?.packListEco}/-`,
        `Rs. ${priceControl?.packListEco}/-`,
      ]);
    }
    if (formData.addDoc1Flag) {
      data.push([
        "4",
        "Additional Document 1",
        "1",
        `Rs. ${priceControl?.addDocEco}/-`,
        `Rs. ${priceControl?.addDocEco}/-`,
      ]);
    }
    if (formData.addDoc2Flag) {
      data.push([
        "5",
        "Additional Document 2",
        "1",
        `Rs. ${priceControl?.addDocEco}/-`,
        `Rs. ${priceControl?.addDocEco}/-`,
      ]);
    }
    // if (formData.refDocFlag) {
    //   data.push(["6", "Reference Document", "1", "Rs. 0/-", "Rs. 0 /-"]);
    // }

    const startY = 120; // Adjusted start position
    const lineHeight = 10;
    const columnWidths = [25, 55, 40, 40, 40]; // Define different widths for each column
    const startX = 15;

    // Draw headers
    let currentX = startX;
    headers.forEach((header, index) => {
      pdf.text(header, currentX, startY);
      currentX += columnWidths[index];
    });

    // Add horizontal line after headers
    const headerLineY = startY + 5;
    pdf.line(startX, headerLineY, 195, headerLineY);

    // Draw data rows
    data.forEach((row, rowIndex) => {
      currentX = startX;
      const yPos = startY + lineHeight * (rowIndex + 1);
      row.forEach((cell, cellIndex) => {
        pdf.text(cell, currentX, yPos);
        currentX += columnWidths[cellIndex];
      });

      // Add horizontal line after each row
      const lineY = yPos + 5; // Adjust the position of the line as needed
      pdf.line(startX, lineY, 195, lineY);
    });

    pdf.text("Processing charge:", 15, startY + lineHeight * (data.length + 1));
    pdf.text(
      `Rs. ${priceControl?.ecoPc}/-`,
      175,
      startY + lineHeight * (data.length + 1)
    );
    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 1),
      195,
      5 + startY + lineHeight * (data.length + 1)
    ); // horizontal line

    pdf.text("Total:", 15, startY + lineHeight * (data.length + 2));
    pdf.text(
      `Rs. ${formData.totalAmt}/-`,
      175,
      startY + lineHeight * (data.length + 2)
    );
    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 2),
      195,
      5 + startY + lineHeight * (data.length + 2)
    ); // horizontal line
    pdf.text(
      `Amount In words(in INR): ${numberToWords(formData.totalAmt)}`,
      15,
      startY + lineHeight * (data.length + 3)
    );

    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 3),
      195,
      5 + startY + lineHeight * (data.length + 3)
    ); // horizontal line

    pdf.text(
      "For Asian Exporters' Chamber of Commerce and Industry",
      195,
      10 + startY + lineHeight * (data.length + 3),
      { align: "right" }
    );
    pdf.text(
      "Authorised Signatory",
      195,
      20 + startY + lineHeight * (data.length + 3),
      { align: "right" }
    );

    pdf.text(
      "Note: This is system generated invoice, no signature required.",
      15,
      20 + startY + lineHeight * (data.length + 3)
    );

    // Save the PDF document as a file
    const pdfBlob = pdf.output("blob"); // Get the PDF document as a Blob object
    const pdfFile = new File([pdfBlob], "receipt.pdf", {
      type: "application/pdf",
    }); // Create a File object from the Blob

    // Store the PDF file in formData.receipt
    formData.receipt = pdfFile;
  };



  // //create eco here--------------------------------------------
  // --------------------------------------------------------------
  // --------------------------------------------------------------
  // --------------------------------------------------------------
  const handleCreateDgftReceipt = async () => {
    // Create a new instance of jsPDF
    const pdf = new jsPDF();

    console.log(Logo);
    // Add logo image
    const pageWidth = pdf.internal.pageSize.getWidth();
    const xOfImg = (pageWidth - 25) / 2;
    pdf.addImage(Logo, "JPEG", xOfImg, 8, 25, 25);

    // Add content to the PDF using addText function
    pdf.setFontSize(20);
    pdf.text("Asian Exporters' Chamber of Commerce And Industry", 22, 45); // Adjusted position for title
    pdf.setFontSize(12);
    pdf.text(
      "Regd Office: 604, 6th Floor, Hilton Centre, Plot No.66, Sector-11, CBD Belapur, Navi Mumbai,",
      20,
      52
    );
    pdf.text("Maharashtra, E-mail: info@aecci.org.in", 68, 56);

    pdf.setFontSize(20);
    pdf.text("INVOICE CUM RECEIPT", 64, 74);
    pdf.line(15, 78, 195, 78); // horizontal line

    pdf.setFontSize(10);
    pdf.text("Receipt No:", 15, 82);
    pdf.text("Document No:", 15, 87);
    pdf.text(`Received From: ${getAuthUserlocal().companyName}`, 15, 92);
    pdf.text("Address: ", 15, 97);
    const address =
      getAuthUserlocal().address1 + " " + getAuthUserlocal().address2;
    const addressLines = pdf.splitTextToSize(address, 60);
    const addLineHeight = 5;
    addressLines.forEach((line, index) => {
      pdf.text(line, 30, 97 + index * addLineHeight);
    });

    //second line
    pdf.text("Date:", 130, 82);
    pdf.text("Time:", 130, 87);
    pdf.text(`AECCI Code: ${getAuthUserlocal().memberShipNo}`, 130, 92);
    pdf.text(`GSTIN: ${getAuthUserlocal().gstNo}`, 130, 97);
    pdf.text(`DGFT CO Ref No:`, 130, 102);
    pdf.text(`${formData.dgftRefNo}`, 130, 107);
    pdf.line(15, 115, 195, 115);

    // Table
    const headers = ["Sr no.", "Particulars", "Quantity", "Rate", "Amount"];

    const data = [];
    if (formData.dgfteCoFileFlag) {
      data.push([
        "1",
        "DGFT electronic-CO (NP)",
        "1",
        `Rs. ${priceControl?.dgft}/-`,
        `Rs. ${priceControl?.dgft}/-`,
      ]);
    }
    if (formData.comInvoiceFlag) {
      data.push([
        "2",
        "Commercial Invoice.",
        "1",
        `Rs. ${priceControl?.comInvoiceEco}/-`,
        `Rs. ${priceControl?.comInvoiceEco}/-`,
      ]);
    }
    if (formData.packingListFlag) {
      data.push([
        "3",
        "Packing List",
        "1",
        `Rs. ${priceControl?.packListEco}/-`,
        `Rs. ${priceControl?.packListEco}/-`,
      ]);
    }
    if (formData.addDoc1Flag) {
      data.push([
        "4",
        "Additional Document 1",
        "1",
        `Rs. ${priceControl?.addDocEco}/-`,
        `Rs. ${priceControl?.addDocEco}/-`,
      ]);
    }
    if (formData.addDoc2Flag) {
      data.push([
        "5",
        "Additional Document 2",
        "1",
        `Rs. ${priceControl?.addDocEco}/-`,
        `Rs. ${priceControl?.addDocEco}/-`,
      ]);
    }
    // if (formData.refDocFlag) {
    //   data.push(["6", "Reference Document", "1", "Rs. 0/-", "Rs. 0 /-"]);
    // }

    const startY = 120; // Adjusted start position
    const lineHeight = 10;
    const columnWidths = [25, 55, 40, 40, 40]; // Define different widths for each column
    const startX = 15;

    // Draw headers
    let currentX = startX;
    headers.forEach((header, index) => {
      pdf.text(header, currentX, startY);
      currentX += columnWidths[index];
    });

    // Add horizontal line after headers
    const headerLineY = startY + 5;
    pdf.line(startX, headerLineY, 195, headerLineY);

    // Draw data rows
    data.forEach((row, rowIndex) => {
      currentX = startX;
      const yPos = startY + lineHeight * (rowIndex + 1);
      row.forEach((cell, cellIndex) => {
        pdf.text(cell, currentX, yPos);
        currentX += columnWidths[cellIndex];
      });

      // Add horizontal line after each row
      const lineY = yPos + 5; // Adjust the position of the line as needed
      pdf.line(startX, lineY, 195, lineY);
    });

    pdf.text("Processing charge:", 15, startY + lineHeight * (data.length + 1));
    pdf.text(
      `Rs. ${priceControl?.ecoPc}/-`,
      175,
      startY + lineHeight * (data.length + 1)
    );
    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 1),
      195,
      5 + startY + lineHeight * (data.length + 1)
    ); // horizontal line

    pdf.text("Total:", 15, startY + lineHeight * (data.length + 2));
    pdf.text(
      `Rs. ${formData.totalAmt}/-`,
      175,
      startY + lineHeight * (data.length + 2)
    );
    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 2),
      195,
      5 + startY + lineHeight * (data.length + 2)
    ); // horizontal line
    pdf.text(
      `Amount In words(in INR): ${numberToWords(formData.totalAmt)}`,
      15,
      startY + lineHeight * (data.length + 3)
    );

    pdf.line(
      15,
      5 + startY + lineHeight * (data.length + 3),
      195,
      5 + startY + lineHeight * (data.length + 3)
    ); // horizontal line

    pdf.text(
      "For Asian Exporters' Chamber of Commerce and Industry",
      195,
      10 + startY + lineHeight * (data.length + 3),
      { align: "right" }
    );
    pdf.text(
      "Authorised Signatory",
      195,
      20 + startY + lineHeight * (data.length + 3),
      { align: "right" }
    );

    pdf.text(
      "Note: This is system generated invoice, no signature required.",
      15,
      20 + startY + lineHeight * (data.length + 3)
    );

    // Save the PDF document as a file
    const pdfBlob = pdf.output("blob"); // Get the PDF document as a Blob object
    const pdfFile = new File([pdfBlob], "receipt.pdf", {
      type: "application/pdf",
    }); // Create a File object from the Blob

    // Store the PDF file in formData.receipt
    formData.receipt = pdfFile;
  };


  // creating e-co here
  const handlePreviewSave = async () => {
    // //validation for eco creation---------------------------------
    // --------------------------------------------------------------
    // --------------------------------------------------------------
    // --------------------------------------------------------------
    const validationRules = {
      consignedFrom: {
        message:
          "Please Enter Goods consigned from field and should 250 letters max.",
        isValid: () => {
          const consignedFrom = formData.eCoDetails.consignedFrom.trim();
          return consignedFrom !== "" && consignedFrom.length <= 250;
        },
      },

      consignedTo: {
        message: "Please Enter Goods consigned to and should 345 letters max.",
        isValid: () => {
          const consignedTo = formData.eCoDetails.consignedTo.trim();
          return consignedTo !== "" && consignedTo.length <= 345;
        },
      },

      meanOfTran: {
        message:
          "Please Enter Means of transport & route and should 97 letters max.",
        isValid: () => {
          const meanOfTran = formData.eCoDetails.meanOfTran.trim();
          return meanOfTran !== "" && meanOfTran.length <= 97;
        },
      },

      israeliDec: {
        message: "Please Enter Israeli Declaration.",
        isValid: () => {
          const israeliDec = formData.eCoDetails.israeliDec;
          return israeliDec !== null && israeliDec !== "";
        },
      },

      orginOfCom: {
        message: "Please select orgin INDIA/FOREIGN ORIGIN.",
        isValid: () => {
          const orginOfComVal = formData.eCoDetails.orginOfCom;
          return orginOfComVal !== null && orginOfComVal !== "";
        },
      },

      designation: {
        message: "Please Select any Authorised Signatory.",
        isValid: () => {
          const designation = formData.eCoDetails.designation.trim();
          return designation !== null && designation !== "";
        },
      },

      impCountry: {
        message: "Please Enter Importing Country and should bw 30 letters max.",
        isValid: () => {
          const impCountry = formData.eCoDetails.impCountry.trim();
          return impCountry !== "" && impCountry.length <= 30;
        },
      },
      forComRegion: {
        message:
          "Please Enter For company/region field and should 40 letters max.",
        isValid: () => {
          const forComRegion = formData.eCoDetails.forComRegion.trim();
          return forComRegion !== "" && forComRegion.length <= 40;
        },
      },

      place: {
        message: "Please Enter place field and should 20 letters max.",
        isValid: () => {
          const place = formData.eCoDetails.place.trim();
          return place !== "" && place.length <= 20;
        },
      },
      date: {
        message: "Please Enter Date.",
        isValid: () => {
          const date = formData.eCoDetails.date.trim();
          return date !== null && date !== "";
        },
      },

      stamp: {
        message: "Please upload stamp.",
        isValid: () => {
          const stamp = formData.eCoDetails.stamp;
          return (
            stamp !== null &&
            stamp.type.startsWith("image/") &&
            stamp.size <= 5 * 1024 * 1024
          ); // 5MB limit
        },
      },
    };

    for (const field in validationRules) {
      const { message, isValid } = validationRules[field];
      if (!isValid()) {
        alert(message);
        return;
      }
    }

    let validationForEco = false;

    {
      formData.eCoDetails.extendedData.map((data, index) => {
        const validationRulesForEco = {
          itemNo: {
            message: `Please Enter page ${index + 1
              } item number field and should be 40 letters max.`,
            isValid: () => {
              const itemNo = data.itemNo.trim();
              return itemNo !== "" && itemNo.length <= 40;
            },
          },
          noOfPack: {
            message: `Please Enter page ${index + 1
              } No of pack field and should be 240 letters max.`,
            isValid: () => {
              const noOfPack = data.noOfPack.trim();
              return noOfPack !== "" && noOfPack.length <= 240;
            },
          },
          desOfGoods: {
            message: `Please Enter page ${index + 1
              } kind of packages, description of goods.`,
            isValid: () => {
              const desOfGoods = data.desOfGoods.trim();
              return desOfGoods !== "" && desOfGoods.length <= 1100;
            },
          },
          originCriteria: {
            message: `Please Enter page ${index + 1
              } Origin Criteria field and should be 40 letters max.`,
            isValid: () => {
              const originCriteria = data.originCriteria.trim();
              return originCriteria !== "" && originCriteria.length <= 40;
            },
          },
          weightAndQuantity: {
            message: `Please Enter page ${index + 1
              } Gross weight or order quantity field and should be 100 letters max.`,
            isValid: () => {
              const weightAndQuantity = data.weightAndQuantity.trim();
              return (
                weightAndQuantity !== "" && weightAndQuantity.length <= 100
              );
            },
          },
          noAndDateInvoice: {
            message: `Please Enter page ${index + 1
              } Number and date of invoices field and should be 200 letters max.`,
            isValid: () => {
              const noAndDateInvoice = data.noAndDateInvoice.trim();
              return noAndDateInvoice !== "" && noAndDateInvoice.length <= 200;
            },
          },
        };

        // Check each validation rule
        for (const field in validationRulesForEco) {
          const { message, isValid } = validationRulesForEco[field];
          if (!isValid()) {
            alert(message);
            validationForEco = true;
            return;
          }
        }
      });
    }

    if (validationForEco) {
      return;
    }

    await handleCreateCo();
    setECoCreated(true);
  };

  const handleSubmitEco = async () => {
    setFormData({
      ...formData,
      eCoFileFlag: true,
    });
    setECoCreated(false);
    setModalShow(false);
  };


  const handleSubmitDgftEcoDetails = async () => {
    // Validate that all fields are filled
    const requiredFields = ["dgftRefNo", "forComRegion", "noAndDateInvoice", "impCountry", "orginOfCom"];
    const missingFields = requiredFields.filter((field) => !formData[field]?.trim());

    // Additional validation for importingCountry if orginOfCom is "FOREIGN ORIGIN"
    if (formData.orginOfCom === "FOREIGN ORIGIN" && !formData.importingCountry?.trim()) {
      missingFields.push("importingCountry");
    }

    if (missingFields.length > 0) {
      alert("All required fields must be filled out. Please complete the form before submitting.");
      return;
    }

    // Set the updated formData and close the modal
    setFormData({
      ...formData,
      dgfteCoDetailsFlag: true,
    });
    setDgftModalShow(false);
  };


  //Main submit for ECO FORM---------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------

  const handleSubmitEcoForm = async () => {
    // await handleCreateReceipt();
    await handleCreateDgftReceipt();

    if (formData.eCoDetails.extendedData.length < 0) {
      alert("Something went wrong");
      return;
    }

    const validationRules = {
      // eCoForm: {
      //   message: "Please Fill eCo form.",
      //   isValid: () => {
      //     const eCOForm = formData.eCoFile;
      //     return eCOForm !== null;
      //   },
      // },

      dgfteCoForm: {
        message: "Please Upload DGFT eCo form.",
        isValid: () => {
          const dgfteCOForm = formData.dgfteCoFile;
          return dgfteCOForm !== null;
        },
      },

      dgftRefNo: {
        message: "Please fill in your DGFT eCo Reference Number.",
        isValid: () => {
          const dgftCoRefNo = formData.dgftRefNo;
          return dgftCoRefNo !== null && dgftCoRefNo.trim() !== "";
        },
      },

      forComRegion: {
        message: "Please fill Exporter Name field.",
        isValid: () => {
          const forComRegion = formData.forComRegion;
          return forComRegion !== null && forComRegion.trim() !== "";
        },
      },

      noAndDateInvoice: {
        message: "Please fill Invoice Number & Date field.",
        isValid: () => {
          const noAndDateInvoice = formData.noAndDateInvoice;
          return noAndDateInvoice !== null && noAndDateInvoice.trim() !== "";
        },
      },


      impCountry: {
        message: "Please fill Invoice Number & Date field.",
        isValid: () => {
          const impCountry = formData.impCountry;
          return impCountry !== null && impCountry.trim() !== "";
        },
      },


      comInvoice: {
        message: "Please upload Commertial Invoice.",
        isValid: () => {
          const comInvoice = formData.comInvoice;
          return (
            comInvoice !== null &&
            comInvoice.type === "application/pdf" &&
            comInvoice.size <= 5 * 1024 * 1024
          );
        },
      },

      packingList: {
        message: "Please upload packing List.",
        isValid: () => {
          const packingList = formData.packingList;
          return (
            packingList !== null &&
            packingList.type === "application/pdf" &&
            packingList.size <= 5 * 1024 * 1024
          );
        },
      },

      addDoc1: {
        message: "Please upload additional document 1.",
        isValid: () => {
          if (formData.addDoc1Flag) {
            const addDoc1 = formData.addDoc1;
            return (
              addDoc1 !== null &&
              addDoc1.type === "application/pdf" &&
              addDoc1.size <= 5 * 1024 * 1024
            );
          }
          return true; // Return true if flag is false (validation skipped)
        },
      },
      addDoc2: {
        message: "Please upload additional document 2.",
        isValid: () => {
          if (formData.addDoc2Flag) {
            const addDoc2 = formData.addDoc2;
            return (
              addDoc2 !== null &&
              addDoc2.type === "application/pdf" &&
              addDoc2.size <= 5 * 1024 * 1024
            );
          }
          return true; // Return true if flag is false (validation skipped)
        },
      },
      refDoc: {
        message: "Please upload reference document.",
        isValid: () => {
          if (formData.refDocFlag) {
            const refDoc = formData.refDoc;
            return (
              refDoc !== null &&
              refDoc.type === "application/pdf" &&
              refDoc.size <= 5 * 1024 * 1024
            );
          }
          return true; // Return true if flag is false (validation skipped)
        },
      },
    };

    for (const field in validationRules) {
      const { message, isValid } = validationRules[field];
      if (!isValid()) {
        alert(message);
        return;
      }
    }
    //api setup started
    const userId = getAuthUserlocal()._id;
    const formDataForApi = new FormData();

    // Append files to the FormData
    const dataToInclude = [
      //data
      "totalAmt",
      "eCoFileFlag",
      "dgfteCoFileFlag",
      "comInvoiceFlag",
      "packingListFlag",
      "addDoc1Flag",
      "addDoc2Flag",
      "refDocFlag",
      "dgftRefNo",


      //files
      "dgfteCoFile",
      "eCoFile",
      "comInvoice",
      "packingList",
      "addDoc1",
      "addDoc2",
      "refDoc",
      "receipt",
    ];

    dataToInclude.forEach((data) => {
      formDataForApi.append(data, formData[data]);
    });

    // Append data to the FormData
    // const fieldsToInclude = [
    //   "israeliDec",
    //   "orginOfCom",
    //   "designation",
    //   "impCountry",
    //   "forComRegion",
    //   "place",
    //   "date",
    //   "stamp",
    // ];
    // fieldsToInclude.forEach((field) => {
    //   formDataForApi.append(field, formData.eCoDetails[field]);
    // });

    // formDataForApi.append(
    //   "noAndDateInvoice",
    //   formData.eCoDetails.extendedData[0].noAndDateInvoice
    // );


    //for DGFT
    const fieldsToInclude = [
      "israeliDec",
      "orginOfCom",
      "impCountry",
      "importingCountry",
      "forComRegion",
    ];
    fieldsToInclude.forEach((field) => {
      formDataForApi.append(field, formData[field])
    });

    formDataForApi.append(
      "noAndDateInvoice",
      formData.noAndDateInvoice
    );

    if (!validateAmount(formData.totalAmt)) {
      alert("Low balance");
      return;
    }

    try {
      setApiSubmitEco(true);

      const res = await axios.post(
        getURLbyEndPoint("createEco") + userId,
        formDataForApi
      );

      if (res.data.status) {
        alert("E-CO created successfully");
        window.location.reload();
        setApiSubmitEco(true);
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    } finally {
      setApiSubmitEco(false);
    }
  };

  //Main Draft function---------------------------------------------
  // -------------------------------------------------------------------------
  // -------------------------------------------------------------------------
  function convertBinaryToBase64(binaryData) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(binaryData);
    });
  }

  const handleSubmitEcoDraft = async () => {
    const userId = getAuthUserlocal()._id;
    const formDataForDraft = new FormData();

    formDataForDraft.append("draftName", draftName);
    formDataForDraft.append(
      "documentType",
      "ELECTRONIC CERTIFICATE OF ORIGIN(eCo)"
    );

    const filesToInclude = [
      "eCoFile",
      "comInvoice",
      "packingList",
      "addDoc1",
      "addDoc2",
      "refDoc",
      "receipt",
      "stamp",
    ];

    try {
      for (const fieldName of filesToInclude) {
        const file = formData[fieldName];
        if (file !== undefined && file !== null) {
          formDataForDraft.append(fieldName, file);
        }
      }

      // Append other fields to formDataForDraft
      formDataForDraft.append("totalAmt", formData.totalAmt);
      formDataForDraft.append("eCoFileFlag", formData.eCoFileFlag);
      formDataForDraft.append("comInvoiceFlag", formData.comInvoiceFlag);
      formDataForDraft.append("packingListFlag", formData.packingListFlag);
      formDataForDraft.append("addDoc1Flag", formData.addDoc1Flag);
      formDataForDraft.append("addDoc2Flag", formData.addDoc2Flag);
      formDataForDraft.append("refDocFlag", formData.refDocFlag);

      const eCoDetailsWithStamp = {
        ...formData.eCoDetails,
        stamp: formData.eCoDetails.stamp
          ? await convertBinaryToBase64(formData.eCoDetails.stamp)
          : null,
      };

      formDataForDraft.append(
        "eCoDetails",
        JSON.stringify(eCoDetailsWithStamp)
      );
      setApiSubmitEcoDraft(true);

      const response = await axios.post(
        getURLbyEndPoint("createEcoDraft") + userId,
        formDataForDraft
      );

      if (response.data.status) {
        alert("Draft saved!!");
        window.location.reload();
        setApiSubmitEcoDraft(true);
      } else {
        alert(response.data.message);
        setApiSubmitEcoDraft(false);
      }
    } catch (error) {
      console.error("Error while saving draft:", error);
      alert("Error while saving draft. Please try again.");
      setApiSubmitEcoDraft(false);
    }
  };

  const openEcoFile = () => {
    // Create URL for the eCoFile
    const eCoFileUrl = URL.createObjectURL(formData.eCoFile);

    // Open the eCoFile in a new tab
    window.open(eCoFileUrl, "_blank");
  };

  const openDgftEcoFile = () => {
    // Create URL for the eCoFile
    const eCoFileUrl = URL.createObjectURL(formData.dgfteCoFile);

    // Open the eCoFile in a new tab
    window.open(eCoFileUrl, "_blank");
  };

  useEffect(() => {
    calculateTotalAmt();
  }, [
    formData.eCoFileFlag,
    formData.comInvoiceFlag,
    formData.packingListFlag,
    formData.addDoc1Flag,
    formData.addDoc2Flag,
  ]);

  // //access control management setup----------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  function handleAccessNav(accessVal, navLink) {
    const currentDate = new Date();
    let userValidity = getAuthUserlocal()?.validUpTo;

    // If userValidity is a string, parse it into a Date object
    if (typeof userValidity === "string") {
      userValidity = new Date(userValidity);
    }

    const daysDifference = (userValidity - currentDate) / (1000 * 60 * 60 * 24);

    if (daysDifference + 1 < 0) {
      alert("Membership is expired!!!");
    } else if (
      !getAuthUserlocal().specialControl?.accessList?.includes(accessVal)
    ) {
      setShowAlert(true)
      // alert("You Don't have access to this page");

    } else {
      setActivePage(navLink);
    }
  }

  return (
    <Layout>
      <Layout2>
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center">
            <div className="main-width">
              <DashboardHeader />

              {/* <hr className="mt-3 mb-2" /> */}

              <div>
                <div className="d-flex align-items-center">
                  {" "}
                  <h4 className="pt-3 pb-2">Trade Documentation</h4>
                  <ModalComponent body={<TradeDocumentationTip />} />
                </div>
                <hr className="mt-1 mb-2" />
                {activePage === "submitEco" && (
                  <>
                    {" "}
                    <h6
                      className="text-left"
                      style={{
                        fontSize: "0.9rem",
                        cursor: "pointer",
                        color: "#5495ff",
                      }}
                      onClick={handleShowDemo}
                    >
                      {" "}
                      How to Submit your eCO?
                    </h6>
                    <DemoModal
                      show={showDemo}
                      heading="How to Submit your eCO?"
                      handleCloseDemo={handleCloseDemo}
                      videoUrl="https://aecci-bucket-new.s3.amazonaws.com/aecci+assets/eCODraftIntro.mp4"
                    />
                  </>
                )}

                {activePage === "nonStandardDoc" && (
                  <>
                    <h6
                      className="text-left"
                      style={{
                        fontSize: "0.9rem",
                        cursor: "pointer",
                        color: "#5495ff",
                      }}
                      onClick={handleShowDemo}
                    >
                      How to submit Non Standard Document?
                    </h6>
                    <DemoModal
                      show={showDemo}
                      heading="How to submit Non Standard Document??"
                      handleCloseDemo={handleCloseDemo}
                      videoUrl="https://aecci-bucket-new.s3.amazonaws.com/aecci+assets/NSD.mp4"
                    />
                  </>
                )}

                {/* <p
                  className="align-content-center my-4"
                  style={{ color: "gray" }}
                >
                  An electronic Certificate of Origin (e-CO) is a digital
                  document that certifies the country of origin of goods. The
                  e-CO replaces the traditional paper-based certificate of
                  origin and offers several advantages, including ease of use,
                  faster processing, reduced paperwork, and enhanced security.
                  AECCI has updated & enhanced the process of shipping Documents
                  & other services digitally. Your business growth is our
                  priority!
                </p> */}

                <table
                  className="table table-bordered mb-3"
                  style={{ tableLayout: "fixed" }}
                >
                  <colgroup>
                    <col style={{ width: "100%" }} />
                  </colgroup>
                  <thead>
                    <tr className="align-middle">
                      <th
                        style={{ backgroundColor: "#0b3b5d", color: "white" }}
                        scope="col"
                        className="text-center align-middle"
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <h5 className="mb-0 text-center flex-grow-1">
                            {activePage === "submitEco"
                              ? "SUBMIT YOUR ELECTRONIC CERTIFICATE OF ORIGIN DOCUMENTS"
                              : activePage === "trackDoc"
                                ? "TRACK YOUR DOCUMENTS"
                                : activePage === "submitManualEco"
                                  ? "SUBMIT YOUR SEMI-ELECTRONIC CERTIFICATE OF ORIGIN DOCUMENTS"
                                  : activePage === "nonStandardDoc"
                                    ? "SUBMIT NON-STANDARD DOCUMENTS"
                                    : activePage === "savedDrafts"
                                      ? "DRAFTS"
                                      : "SUBMIT YOUR DOCUMENT"}
                          </h5>
                          {(activePage === "submitEco" ||
                            activePage === "trackDoc" ||
                            activePage === "savedDrafts" ||
                            activePage === "submitManualEco" ||
                            activePage === "nonStandardDoc") && (
                              <div className="ml-auto">
                                <a
                                  href="#"
                                  className="fw-semibold text-white"
                                  onClick={handleBack}
                                >
                                  &lt; BACK
                                </a>
                              </div>
                            )}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {activePage === "homePage" ? (
                      <tr>
                        <td>
                          <div class="row">
                            <div class="col ">
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ "flex-direction": "column" }}
                              >
                                <img
                                  src={submitEco}
                                  style={
                                    getAuthUserlocal().specialControl?.accessList?.includes(
                                      "ECO"
                                    )
                                      ? { width: "33%" }
                                      : {
                                        width: "33%",
                                        filter: "grayscale(100%)",
                                        opacity: "0.5",
                                      }
                                  }
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleAccessNav("ECO", "submitEco")
                                  }
                                />
                                <div className="text-center">
                                  <h6>Electronic Certificate of Origin</h6>
                                  <span className="d-block">
                                    (Self-Print by User*)
                                  </span>
                                  <ModalComponent body={<EcoButton />} />
                                </div>
                              </div>
                            </div>

                            <div class="col ">
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ "flex-direction": "column" }}
                              >
                                <img
                                  src={submitEco}
                                  style={
                                    getAuthUserlocal().specialControl?.accessList?.includes(
                                      "MCO"
                                    )
                                      ? { width: "33%" }
                                      : {
                                        width: "33%",
                                        filter: "grayscale(100%)",
                                        opacity: "0.5",
                                      }
                                  }
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleAccessNav("MCO", "submitManualEco")
                                  }
                                />
                                <div className="text-center">
                                  <h6>Semi-Electronic Certificate of Origin</h6>
                                  <span className="d-block">
                                    (Printed at Chamber's Premises**)
                                  </span>
                                  <ModalComponent body={<ManualCoButton />} />
                                </div>
                              </div>
                            </div>

                            <div class="col ">
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ "flex-direction": "column" }}
                              >
                                <img
                                  src={submitEco}
                                  style={
                                    getAuthUserlocal().specialControl?.accessList?.includes(
                                      "nonStandard"
                                    )
                                      ? { width: "33%" }
                                      : {
                                        width: "33%",
                                        filter: "grayscale(100%)",
                                        opacity: "0.5",
                                      }
                                  }
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleAccessNav(
                                      "nonStandard",
                                      "nonStandardDoc"
                                    )
                                  }
                                />
                                <div className="text-center">
                                  <h6>Non-Standard Documents</h6>
                                  <span className="d-block">
                                    (POA/LOA/Agreement)
                                  </span>

                                  {getAuthUserlocal().specialControl?.accessList?.includes(
                                    "nonStandard"
                                  ) ? (
                                    <ModalComponent
                                      body={<NonStandButton />}
                                      disabled={false}
                                    />
                                  ) : (
                                    <ModalComponent
                                      body={<NonStandButton />}
                                      disabled={true}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="row m-auto">
                              <div
                                className="d-flex justify-content-between gap-3"
                                style={{ "flex-direction": "row" }}
                              >
                                <div className="d-flex align-items-center gap-3 w-100">
                                  <ModalComponent body={<DraftTip />} />

                                  {/* Conditional rendering for "DRAFTS" button */}
                                  <button
                                    size="lg"
                                    className="btn btn-secondary flex-grow-1"
                                    onClick={() => setActivePage("savedDrafts")}
                                  >
                                    DRAFTS
                                  </button>

                                  {/* Conditional rendering for "TRACK YOUR DOCUMENTS" button */}
                                  <button
                                    className="btn btn-success flex-grow-1"
                                    onClick={() => setActivePage("trackDoc")}
                                  >
                                    TRACK YOUR DOCUMENTS
                                  </button>
                                </div>

                                {/* <ModalComponent body={<TrackDocButton />} /> */}
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            {/* <button
                      className="btn rounded btn-success"
                      style={{ width: "100%" }}
                      onClick={() => {
                        navigate("/e-co-verification");
                      }}
                    >
                      Verify Your Documents
                    </button> */}
                          </div>
                        </td>
                      </tr>
                    ) : activePage === "submitEco" ? (
                      // ---------------------------------------------------------------------------------------------------------------
                      // -------------------------------SUBMIT YOUR DOCUMENT------------------------------------------------------------
                      // ---------------------------------------------------------------------------------------------------------------
                      // ---------------------------------------------------------------------------------------------------------------

                      <>
                        <div>
                          <Table bordered striped>
                            <thead>
                              <tr>
                                <th
                                  className="fw-medium"
                                  style={{ width: "40%" }}
                                >
                                  DOCUMENT TYPE
                                </th>
                                <th
                                  className="fw-medium"
                                  style={{ width: "20%" }}
                                >
                                  FEES
                                </th>
                                <th
                                  className="fw-medium"
                                  style={{ width: "20%" }}
                                >
                                  UPLOADS
                                </th>
                                <th
                                  className="fw-medium"
                                  style={{ width: "20%" }}
                                >
                                  ACTION
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {/* //DGFT co row------------------------------
                              --------------------------------------------
                              ------------------------------------------- */}
                              <tr>
                                <td>
                                  <div className="d-flex flex-column gap-2 w-100">
                                    <div className="d-flex align-items-center">
                                      <h6>DGFT electronic-CO (NP)*</h6>
                                      <PopUpModal body={<DgftTip />} />
                                    </div>
                                    <div className="d-flex align-items-center w-100">
                                      {/* <Form.Group>
                                        <Form.Label className='fs-6 fw-semibold'>
                                          Enter your DGFT reference number
                                          <OverlayTrigger
                                            placement="right"
                                            overlay={
                                              <Tooltip
                                                id="tooltip-dgft"
                                                style={{
                                                  maxWidth: '400px',
                                                  width: '400px',
                                                  padding: '10px',
                                                  textAlign: 'center',
                                                  zIndex: 9999,
                                                }}
                                              >
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                  <img
                                                    src={DgftRefEg}
                                                    alt="Tip Image"
                                                    style={{ width: '100%', height: 'auto' }}
                                                  />
                                                  <div className='fs-6'>Reference number will be shown at the top of the ECO.</div>
                                                </div>
                                              </Tooltip>
                                            }
                                          >
                                            <span className="ms-2 text-danger fw-bold" style={{ cursor: 'pointer' }}>?</span>
                                          </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          value={formData.dgftRefNo}
                                          name="dgftRefNo"
                                          onChange={(e) => handleInputChange("dgftRefNo", e.target.value)}
                                          placeholder="e.g., XX/YYYY/XXX/XXXXXXXXX/XXXXXXXX"
                                          maxLength={35}
                                          className="w-100"
                                        />
                                      </Form.Group> */}
                                      <Button
                                        variant="secondary"
                                        onClick={() => setDgftModalShow(true)}
                                        className="w-75 setup-bg-color"
                                        disabled={formData.dgfteCoDetailsFlag}
                                      >
                                        Submit Your Document Information
                                      </Button>
                                    </div>
                                  </div>
                                </td>

                                <td>Rs. {priceControl?.dgft}/-</td>
                                <td>
                                  <Form.Group>
                                    <div className='fw-semibold'>Upload your DGFT eco</div>
                                    <div className="my-2">
                                      <label
                                        htmlFor="file-upload"
                                        className={`btn w-100 setup-bg-color ${formData.dgfteCoFileFlag ? "btn-secondary disabled" : "btn-secondary"
                                          }`}
                                        style={{
                                          cursor: formData.dgfteCoFileFlag ? "not-allowed" : "pointer",
                                          backgroundColor: formData.dgfteCoFileFlag ? "#a7a7a7" : "",
                                        }}
                                      >
                                        Upload File
                                      </label>
                                      <Form.Control
                                        id="file-upload"
                                        as="input"
                                        type="file"
                                        accept="application/pdf"
                                        style={{ display: "none" }}
                                        onChange={(e) => {
                                          handleFileChange(e, "dgfteCoFile");
                                        }}
                                        disabled={formData.dgfteCoFileFlag}
                                      />
                                    </div>
                                  </Form.Group>
                                </td>
                                <td className="align-middle">
                                  <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                                    {formData.dgfteCoFile && (
                                      <BsEye
                                        className="fs-5 cursor-pointer"
                                        onClick={openDgftEcoFile}
                                      />
                                    )}
                                    <FaPen
                                      className="fs-6 cursor-pointer"
                                      onClick={() => {
                                        setFormData({
                                          ...formData,
                                          dgfteCoFileFlag: false,
                                          dgfteCoDetailsFlag: false,
                                          dgfteCoFile: null,
                                        });
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="4" className="text-center">
                                  <strong>OR</strong>
                                </td>
                              </tr>
                              {/* //e-co row------------------------------
                              --------------------------------------------
                              ------------------------------------------- */}
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <h6>electronic-CO (NP)*</h6>
                                    <div>
                                      <PopUpModal disabled body={<ElectronicCo />} />
                                    </div>
                                  </div>
                                </td>
                                {/* <td>Rs. {priceControl?.eco}/-</td> */}
                                <td>
                                  --
                                </td>
                                <td>
                                  <Button
                                    variant="secondary"
                                    onClick={() => setModalShow(true)}
                                    className="w-100 setup-bg-color"
                                    // disabled={formData.eCoFileFlag}
                                    disabled
                                  >
                                    Fill Form
                                  </Button>
                                </td>
                                <td className="align-middle">
                                  <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                                    {formData.eCoFile && (
                                      <BsEye
                                        className="fs-5 cursor-pointer"
                                        disabled
                                      // onClick={openEcoFile}
                                      />
                                    )}
                                    <FaPen
                                      className="fs-6 cursor-pointer"
                                      disabled
                                    // onClick={() => setModalShow(true)}
                                    />
                                  </div>
                                </td>
                              </tr>
                              {/* commertial invoice row--------------------------
                              -----------------------------------------------------
                              ------------------------------------------------------- */}
                              {formData.eCoFileFlag || formData.dgfteCoFileFlag && (
                                <tr>
                                  <td>
                                    <h6>
                                      <div className="d-flex align-items-center">
                                        <h6>Commercial Invoice</h6>
                                        <div>
                                          <PopUpModal
                                            body={<ComInvoiceTip />}
                                          />
                                        </div>
                                      </div>
                                    </h6>
                                  </td>
                                  <td>
                                    {formData.comInvoiceFlag
                                      ? `Rs. ${priceControl?.comInvoiceEco}/-`
                                      : "Rs.0/-"}
                                  </td>

                                  <td>
                                    <div className="d-flex flex-column justify-content-center">
                                      <div className="d-inline ms-3 align-middle mb-3">
                                        <Form.Check
                                          inline
                                          label="Yes"
                                          name="comInvoice"
                                          type="radio"
                                          checked={formData.comInvoiceFlag}
                                          onChange={() => {
                                            const updatedFormData = {
                                              ...formData,
                                              comInvoiceFlag: true,
                                            };
                                            setFormData(updatedFormData);
                                          }}
                                        />
                                        <Form.Check
                                          inline
                                          label="No"
                                          name="comInvoice"
                                          type="radio"
                                          checked={!formData.comInvoiceFlag}
                                          onChange={() => {
                                            const updatedFormData = {
                                              ...formData,
                                              comInvoiceFlag: false,
                                            };
                                            setFormData(updatedFormData);
                                          }}
                                        />
                                      </div>

                                      <div>
                                        {/* Hidden file input for commercial invoice */}
                                        <input
                                          type="file"
                                          id="comInvoice"
                                          accept=".pdf" // Add accepted file types if needed
                                          onChange={(event) =>
                                            handleFileChange(
                                              event,
                                              "comInvoice"
                                            )
                                          }
                                          style={{ display: "none" }}
                                        />

                                        {/* Button and associated label for commercial invoice */}
                                        <Button
                                          className="setup-bg-color w-100 mb-2"
                                          disabled={formData.comInvoice}
                                        >
                                          <label
                                            htmlFor="comInvoice"
                                            className="mb-0"
                                          >
                                            Upload
                                          </label>
                                        </Button>
                                      </div>

                                      {!formData.comInvoiceFlag && (
                                        <span
                                          style={{ fontSize: "14px" }}
                                          className="text-center"
                                        >
                                          Upload commercial Invoice for
                                          Reference Only
                                        </span>
                                      )}
                                    </div>
                                  </td>

                                  <td className="align-middle">
                                    <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                                      <a
                                        href="#"
                                        onClick={() =>
                                          viewDocument("comInvoice")
                                        }
                                        disabled={!formData.comInvoice}
                                      >
                                        <BsEye className="fs-5 cursor-pointer" />
                                      </a>

                                      <FaPen
                                        className="fs-6 cursor-pointer"
                                        onClick={() =>
                                          document
                                            .getElementById("comInvoice")
                                            .click()
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {/* Packing List row--------------------------
                              -----------------------------------------------------
                              ------------------------------------------------------- */}
                              {formData.comInvoice && (
                                <tr>
                                  <td>
                                    <h6>
                                      <div className="d-flex align-items-center">
                                        <h6>Packing List*</h6>
                                        <div>
                                          <PopUpModal body={<PackListTip />} />
                                        </div>
                                      </div>
                                    </h6>
                                  </td>
                                  <td>
                                    {formData.packingListFlag
                                      ? `Rs. ${priceControl?.packListEco}/-`
                                      : "Rs.0/-"}
                                  </td>

                                  <td>
                                    <div className="d-flex flex-column justify-content-center">
                                      <div className="d-inline ms-3 align-middle mb-3">
                                        <Form.Check
                                          inline
                                          label="Yes"
                                          name="packList"
                                          type="radio"
                                          checked={formData.packingListFlag}
                                          onChange={() => {
                                            const updatedFormData = {
                                              ...formData,
                                              packingListFlag: true,
                                            };
                                            setFormData(updatedFormData);
                                          }}
                                        />
                                        <Form.Check
                                          inline
                                          label="No"
                                          name="packList"
                                          type="radio"
                                          checked={!formData.packingListFlag}
                                          onChange={() => {
                                            const updatedFormData = {
                                              ...formData,
                                              packingListFlag: false,
                                            };
                                            setFormData(updatedFormData);
                                          }}
                                        />
                                      </div>

                                      <div>
                                        {/* Hidden file input for commercial invoice */}
                                        <input
                                          type="file"
                                          id="packingList"
                                          accept=".pdf" // Add accepted file types if needed
                                          onChange={(event) =>
                                            handleFileChange(
                                              event,
                                              "packingList"
                                            )
                                          }
                                          style={{ display: "none" }}
                                        />

                                        {/* Button and associated label for commercial invoice */}
                                        <Button
                                          className="setup-bg-color w-100 mb-2"
                                          disabled={formData.packingList}
                                        >
                                          <label
                                            htmlFor="packingList"
                                            className="mb-0"
                                          >
                                            Upload
                                          </label>
                                        </Button>
                                      </div>

                                      {!formData.packingListFlag && (
                                        <span
                                          style={{ fontSize: "14px" }}
                                          className="text-center"
                                        >
                                          Upload Packing List for Reference Only{" "}
                                        </span>
                                      )}
                                    </div>
                                  </td>

                                  <td className="align-middle">
                                    <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                                      <a
                                        href="#"
                                        onClick={() =>
                                          viewDocument("packingList")
                                        }
                                        disabled={!formData.packingList}
                                      >
                                        <BsEye className="fs-5 cursor-pointer" />
                                      </a>

                                      <FaPen
                                        className="fs-6 cursor-pointer"
                                        onClick={() =>
                                          editDocument("packingList")
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {/* 
                            ---------------------additional Document 1---------------------------
                            ---------------------------------------------------------------------
                            ---------------------------------------------------------------------
                            --------------------------------------------------------------------- */}
                              {formData.addDoc1Flag && (
                                <tr>
                                  <td>
                                    <h6>
                                      <div className="d-flex align-items-center">
                                        <h6>Additional Document 1</h6>
                                        <div>
                                          <PopUpModal body={<AddDocTips />} />
                                        </div>
                                      </div>
                                    </h6>
                                  </td>
                                  <td>Rs. {priceControl?.addDocEco}/-</td>

                                  <td>
                                    <div className="d-flex flex-column justify-content-center">
                                      {/* Hidden file input for commercial invoice */}
                                      <input
                                        type="file"
                                        id="addDoc1"
                                        accept=".pdf" // Add accepted file types if needed
                                        onChange={(event) =>
                                          handleFileChange(event, "addDoc1")
                                        }
                                        style={{ display: "none" }}
                                      />

                                      {/* Button and associated label for commercial invoice */}
                                      <Button
                                        className="setup-bg-color w-100 mb-2"
                                        disabled={formData.addDoc1}
                                      >
                                        <label
                                          htmlFor="addDoc1"
                                          className="mb-0"
                                        >
                                          Upload
                                        </label>
                                      </Button>
                                    </div>
                                  </td>

                                  <td className="align-middle">
                                    <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                                      <a
                                        href="#"
                                        onClick={() => viewDocument("addDoc1")}
                                        disabled={!formData.addDoc1}
                                      >
                                        <BsEye className="fs-5 cursor-pointer" />
                                      </a>

                                      <FaPen
                                        className="fs-6 cursor-pointer"
                                        onClick={() => editDocument("addDoc1")}
                                      />

                                      {!formData.addDoc2Flag && (
                                        <MdDelete
                                          className="fs-5 cursor-pointer"
                                          onClick={() => {
                                            setFormData({
                                              ...formData,
                                              addDoc1: null,
                                              addDoc1Flag: false,
                                            });
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {/* 
                            ---------------------additional Document 2---------------------------
                            ---------------------------------------------------------------------
                            ---------------------------------------------------------------------
                            --------------------------------------------------------------------- */}
                              {formData.addDoc2Flag && (
                                <tr>
                                  <td>
                                    <h6>
                                      <div className="d-flex align-items-center">
                                        <h6>Additional Document 2</h6>
                                        <div>
                                          <PopUpModal body={<AddDocTips />} />
                                        </div>
                                      </div>
                                    </h6>
                                  </td>
                                  <td>Rs. {priceControl?.addDocEco}/-</td>

                                  <td>
                                    <div className="d-flex flex-column justify-content-center">
                                      {/* Hidden file input for commercial invoice */}
                                      <input
                                        type="file"
                                        id="addDoc2"
                                        accept=".pdf" // Add accepted file types if needed
                                        onChange={(event) =>
                                          handleFileChange(event, "addDoc2")
                                        }
                                        style={{ display: "none" }}
                                      />

                                      {/* Button and associated label for commercial invoice */}
                                      <Button
                                        className="setup-bg-color w-100 mb-2"
                                        disabled={formData.addDoc2}
                                      >
                                        <label
                                          htmlFor="addDoc2"
                                          className="mb-0"
                                        >
                                          Upload
                                        </label>
                                      </Button>
                                    </div>
                                  </td>

                                  <td className="align-middle">
                                    <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                                      <a
                                        href="#"
                                        onClick={() => viewDocument("addDoc2")}
                                        disabled={!formData.addDoc2}
                                      >
                                        <BsEye className="fs-5 cursor-pointer" />
                                      </a>
                                      <FaPen
                                        className="fs-6 cursor-pointer"
                                        onClick={() => editDocument("addDoc2")}
                                      />

                                      <MdDelete
                                        className="fs-5 cursor-pointer"
                                        onClick={() => {
                                          setFormData({
                                            ...formData,
                                            addDoc2: null,
                                            addDoc2Flag: false,
                                          });
                                        }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {/* 
                            ---------------------Refrence document ---------------------------
                            ---------------------------------------------------------------------
                            ---------------------------------------------------------------------
                            --------------------------------------------------------------------- */}
                              {formData.refDocFlag && (
                                <tr>
                                  <td>
                                    <h6>
                                      <div className="d-flex align-items-center">
                                        <h6>Reference Document*</h6>
                                        <div>
                                          <PopUpModal body={<RefDocTips />} />
                                        </div>
                                      </div>
                                    </h6>
                                  </td>
                                  <td>Rs.0/-</td>

                                  <td>
                                    <div className="d-flex flex-column justify-content-center">
                                      {/* Hidden file input for commercial invoice */}
                                      <input
                                        type="file"
                                        id="refDoc"
                                        accept=".pdf" // Add accepted file types if needed
                                        onChange={(event) =>
                                          handleFileChange(event, "refDoc")
                                        }
                                        style={{ display: "none" }}
                                      />

                                      {/* Button and associated label for commercial invoice */}
                                      <Button
                                        className="setup-bg-color w-100 mb-2"
                                        disabled={formData.refDoc}
                                      >
                                        <label
                                          htmlFor="refDoc"
                                          className="mb-0"
                                        >
                                          Upload
                                        </label>
                                      </Button>
                                    </div>
                                  </td>

                                  <td className="align-middle">
                                    <div className="d-flex justify-content-center align-items-center gap-3 text-secondary">
                                      <a
                                        href="#"
                                        onClick={() => viewDocument("refDoc")}
                                        disabled={!formData.refDoc}
                                      >
                                        <BsEye className="fs-5 cursor-pointer" />
                                      </a>
                                      <FaPen
                                        className="fs-6 cursor-pointer"
                                        onClick={() => editDocument("refDoc")}
                                      />

                                      <MdDelete
                                        className="fs-5 cursor-pointer"
                                        onClick={() => {
                                          setFormData({
                                            ...formData,
                                            refDoc: null,
                                            refDocFlag: false,
                                          });
                                        }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {/* ---------------------Documents end here ---------------------------
                            ---------------------------------------------------------------------
                            ---------------------------------------------------------------------
                            --------------------------------------------------------------------- */}

                              <tr>
                                <td colSpan={4}>
                                  <div className="d-flex justify-content-evenly w-100">
                                    {!formData.addDoc2Flag && (
                                      <Button
                                        className="small-font my-1 fw-semibold px-3 setup-bg-color"
                                        onClick={handleAddFileClick}
                                      >
                                        SUBMIT ADDITIONAL DOCUMENT?
                                      </Button>
                                    )}

                                    {!formData.refDocFlag && (
                                      <Button
                                        className="small-font my-1 fw-semibold px-3"
                                        variant="info"
                                        onClick={() =>
                                          setFormData({
                                            ...formData,
                                            refDocFlag: true,
                                          })
                                        }
                                      >
                                        {" "}
                                        SUBMIT REFRENCE DOCUMENT?
                                      </Button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>
                                    <div className="d-flex align-items-center">
                                      <h6>Processing Fee</h6>
                                    </div>
                                  </h6>
                                </td>
                                <td>Rs. {priceControl?.ecoPc}/-</td>

                                <td></td>

                                <td></td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Total</h6>
                                </td>

                                <td>
                                  <span>Rs.{formData.totalAmt}/-</span>
                                </td>
                                <td colSpan={2} className="text-end">
                                  <PreviewReceipt formData={formData} />

                                  <ProcessedPayment
                                    formData={formData}
                                    setIsSubmit={setIsSubmit}
                                    isTrial={
                                      (getAuthUserlocal()?.isTrial
                                        ? getAuthUserlocal()?.isTrial
                                        : false) &&
                                      getAuthUserlocal()?.balance -
                                      formData.totalAmt <
                                      500
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </Table>

                          <div className="d-flex justify-content-between gap-3">
                            <div className="d-flex align-items-center gap-4">
                              <div>
                                <ModalComponent
                                  className="w-4"
                                  body={<DraftTip />}
                                />

                                <Button
                                  onClick={() => {
                                    setModalIsOpen(true);
                                    setIsSubmitDraft(true);
                                  }}
                                >
                                  Save Draft
                                </Button>
                              </div>

                              <Modal
                                show={modalIsOpen}
                                onHide={() => {
                                  setModalIsOpen(false);
                                  setIsSubmitDraft(false);
                                }}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Enter Draft Name</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <Form.Control
                                    type="text"
                                    value={draftName}
                                    onChange={(e) =>
                                      setDraftName(e.target.value)
                                    }
                                    placeholder="Enter Draft Name"
                                  />
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={() => setModalIsOpen(false)}
                                  >
                                    Close
                                  </Button>

                                  <Button
                                    variant="success"
                                    disabled={
                                      !isSubmitDraft || apiSubmitEcoDraft
                                    }
                                    onClick={handleSubmitEcoDraft}
                                  >
                                    {apiSubmitEcoDraft
                                      ? "Processing..."
                                      : "Save"}
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                              <Button variant="danger" onClick={resetFormData}>
                                RESET
                              </Button>
                            </div>
                            <div>
                              <Button
                                variant="success"
                                disabled={!isSubmit || apiSubmitEco}
                                onClick={handleSubmitEcoForm}
                              >
                                {apiSubmitEco ? "Processing..." : "SUBMIT"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : activePage === "trackDoc" ? (
                      <>
                        <div>
                          <Nav
                            variant="pills"
                            defaultActiveKey="eco"
                            onSelect={handleSelect}
                            className="primary-color fw-semibold gap-3 justify-content-center align-items-center verification-tabs"
                            style={{ color: "#0b3b5d" }}
                          >
                            <NavItem
                              style={{ "min-width": "15%" }}
                              className="text-center"
                            >
                              <NavLink eventKey="eco">
                                Electronic Certificate of Origin
                              </NavLink>
                            </NavItem>

                            <NavItem
                              style={{ "min-width": "15%" }}
                              className="text-center"
                            >
                              <NavLink eventKey="manual">
                                Semi-Electronic Certificate of Origin
                              </NavLink>
                            </NavItem>

                            <NavItem
                              style={{ "min-width": "15%" }}
                              className="text-center"
                            >
                              <NavLink eventKey="nonstandard">
                                Non-Standard Documents
                              </NavLink>
                            </NavItem>
                          </Nav>

                          {activeTable === "eco" && <ECoDataTrack />}

                          {activeTable === "manual" && <McoDataTrack />}

                          {activeTable === "nonstandard" && <NonStandTrack />}

                          {/* Your existing pagination logic here */}
                        </div>
                      </>
                    ) : activePage === "savedDrafts" ? (
                      <>
                        <DraftTrack />
                      </>
                    ) : activePage === "submitManualEco" ? (
                      <>
                        <ManualCo />
                      </>
                    ) : activePage === "nonStandardDoc" ? (
                      <>
                        <NonStandDoc />
                      </>
                    ) : (
                      <div>Something went Wrong!!!</div>
                    )}
                  </tbody>
                </table>
                <AlertPopup isOpen={showAlert} toggle={toggleAlertModal} />
                <Contactinfo />
                <QuickLinks />
              </div>
              {/* {activePage === "homePage" ? (
              ) : activePage === "submitEco" ? (
                <div>
                  {" "}
                  <div className="d-flex align-items-center">
                    {" "}
                    <h4 className="py-4">
                      <a href="#" onClick={() => setActivePage("homePage")}>
                        e-CO
                      </a>
                      / Submit Document
                    </h4>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip>e-Co and Track Documents</Tooltip>}
                    >
                      <img
                        src={infoImg}
                        style={{ height: "6vh" }}
                        alt=""
                        srcset=""
                      />
                    </OverlayTrigger>
                  </div>
                  <hr className="mt-1 mb-2" />
                </div>
              ) : activePage === "trackDoc" ? (
                <div>
                  {" "}
                  <div className="d-flex align-items-center">
                    {" "}
                    <h4 className="py-4">
                      <a href="#" onClick={() => setActivePage("homePage")}>
                        e-CO
                      </a>
                      / Track Your Document
                    </h4>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip>e-Co and Track Documents</Tooltip>}
                    >
                      <img
                        src={infoImg}
                        style={{ height: "6vh" }}
                        alt=""
                        srcset=""
                      />
                    </OverlayTrigger>
                  </div>
                  <hr className="mt-1 mb-2" />
                </div>
              ) : (
                <div>Something went Wrong!!!</div>
              )} */}

              <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                formData={formData}
                setFormData={setFormData}
                handlePreviewSave={handlePreviewSave}
                eCoCreated={eCoCreated}
                setECoCreated={setECoCreated}
                handleSubmitEco={handleSubmitEco}
                handleAddEcoForm={handleAddEcoForm}
              />


              <DgftDocInfoModal
                show={dgftModalShow}
                onHide={() => setDgftModalShow(false)}
                formData={formData}
                setFormData={setFormData}
                handleSubmitDgftEcoDetails={handleSubmitDgftEcoDetails}
                handleInputChange={handleInputChange}
              />
            </div>
          </div>

          {/* <div style={{ height: "90vh", overflow: "hidden" }}>
              <iframe
                title="ECO iFrame"
                src={`https://eservices.aecci.org.in/?userId=${userId}`}
                width="100%"
                height="90%"
                frameBorder="0"
                scrolling="auto"
              ></iframe>
            </div> */}
        </div>
      </Layout2>
    </Layout>
  );
};

export default ECO;


//Dgft Document Info Modal
function DgftDocInfoModal(props) {
  const { formData, setFormData, handleInputChange, handleSubmitDgftEcoDetails } = props;

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <div className="d-flex justify-content-center w-100">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <h4>Submit Your eCo Information</h4>
          </Modal.Title>
        </div>
        <CloseButton onClick={props.onHide} />
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <div className="row g-3">
            <div className="col-md-6">
              <Form.Group>
                <Form.Label className="fs-6 fw-semibold">
                  Enter your DGFT reference number
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        id="tooltip-dgft"
                        style={{
                          maxWidth: '400px',
                          width: '400px',
                          padding: '10px',
                          textAlign: 'center',
                          zIndex: 9999,
                        }}
                      >
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          <img
                            src={DgftRefEg}
                            alt="Tip Image"
                            style={{ width: '100%', height: 'auto' }}
                          />
                          <div className='fs-6'>Reference number will be shown at the top of the ECO.</div>
                        </div>
                      </Tooltip>
                    }
                  >
                    <span className="ms-2 text-danger fw-bold" style={{ cursor: 'pointer' }}>?</span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={formData.dgftRefNo}
                  name="dgftRefNo"
                  required
                  onChange={(e) => handleInputChange("dgftRefNo", e.target.value)}
                  placeholder="e.g., XX/YYYY/XXX/XXXXXXXXX/XXXXXXXX"
                  maxLength={35}
                  className="w-100"
                />
              </Form.Group>
            </div>

            <div className="col-md-6">
              <Form.Group>
                <Form.Label className="fs-6 fw-semibold">
                  Exporter Name
                </Form.Label>
                <Form.Control
                  type="text"
                  value={formData.forComRegion}
                  name="forComRegion"
                  required
                  placeholder="enter your exporter name"
                  onChange={(e) => handleInputChange("forComRegion", e.target.value)}
                  maxLength={50}
                />
              </Form.Group>
            </div>

            <div className="col-md-6">
              <Form.Group>
                <Form.Label className="fs-6 fw-semibold">
                  Invoice Number & Date
                </Form.Label>
                <Form.Control
                  type="text"
                  value={formData.noAndDateInvoice}
                  name="noAndDateInvoice"
                  placeholder="e.g., INVOICE NO 123456789 DATE: DD/MM/YYYY"
                  required
                  onChange={(e) => handleInputChange("noAndDateInvoice", e.target.value)}
                  maxLength={80}
                />
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group>
                <Form.Label className="fs-6 fw-semibold">
                  Final Destination
                </Form.Label>
                <Form.Control
                  type="text"
                  value={formData.impCountry}
                  name="impCountry"
                  required
                  placeholder='enter your final destination country'
                  onChange={(e) => handleInputChange("impCountry", e.target.value)}
                  maxLength={80}
                />
              </Form.Group>
            </div>
            <div className="mt-3 col-md-12">
              <span>
                The Undersigned hereby declares that the above details and
                statements are correct that all the goods were produced in{" "}
                <div className="d-inline-block">
                  <Form.Check
                    className="me-0"
                    inline
                    name="orginOfCom"
                    label="INDIA"
                    type="radio"
                    checked={formData.orginOfCom === "INDIA"}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        orginOfCom: "INDIA",
                        orginOfComFlag: false,
                      })
                    }
                  />{" "}
                  <Form.Check
                    className="me-0"
                    inline
                    name="orginOfCom"
                    label="FOREIGN ORIGIN"
                    type="radio"
                    checked={formData.orginOfCom === "FOREIGN ORIGIN"}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        orginOfCom: "FOREIGN ORIGIN",
                        orginOfComFlag: true,
                      })
                    }
                  />
                </div>{" "}
                and that they comply with the origin requirements.
              </span>
            </div>
            {formData.orginOfComFlag &&
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="fs-6 fw-semibold">
                    Importing Country Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.importingCountry}
                    name="importingCountry"
                    onChange={(e) => handleInputChange("importingCountry", e.target.value)}
                    placeholder="Importing Country Name"
                    maxLength={80}
                  />
                </Form.Group>
                <Form.Text className="text-muted">
                  (Refer to clause 8 of the certificate of origin criteria)
                </Form.Text>
              </div>
            }
          </div>
        </div>



      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Button
                onClick={handleSubmitDgftEcoDetails}
                variant="success"
                className="w-25"
              >
                SUBMIT
              </Button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}


//modal is here

function MyVerticallyCenteredModal(props) {
  const { formData } = props;
  const { setFormData } = props;
  const { handlePreviewSave } = props;
  const { eCoCreated } = props;
  const { setECoCreated } = props;
  const { handleSubmitEco } = props;
  const { handleAddEcoForm } = props;

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{ backgroundColor: "#0b3b5d", color: "white" }}
        className="text-white"
      >
        <div className="d-flex justify-content-center w-100">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            <h4>CERTIFICATE OF ORIGIN (NON PREFERENTIAL)</h4>
            <h6>(COMBINED DECLARATION AND CERTIFICATE ISSUE-O IN INDIA)</h6>
          </Modal.Title>
        </div>
        <CloseButton variant="white" onClick={props.onHide} />
      </Modal.Header>

      <Modal.Body style={{ backgroundColor: "#f2f2f2" }}>
        {!eCoCreated ? (
          <>
            <Row>
              <Col md={7}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    1. Goods consigned from (Exporter's business name, address,
                    country)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    className="mb-1"
                    rows={4}
                    style={{ resize: "none" }}
                    maxLength={250}
                    placeholder="Enter Exporter business name, address, country"
                    value={formData.eCoDetails.consignedFrom}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        eCoDetails: {
                          ...formData.eCoDetails,
                          consignedFrom: e.target.value,
                        },
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const lines = e.target.value.split("\n");
                        if (lines.length >= 6) {
                          e.preventDefault();
                        }
                      }
                    }}
                  />
                  <h6 className="float-end fw-normal text-small text-secondary">
                    {formData.eCoDetails.consignedFrom.length}/250 Max
                  </h6>
                  <hr className="mt-4" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    2. Goods consigned to (Consignee's business name, address,
                    country)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    style={{ resize: "none" }}
                    type="text"
                    className="mb-1"
                    maxLength={345}
                    placeholder="Enter Consignee business name, address, country"
                    value={formData.eCoDetails.consignedTo}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        eCoDetails: {
                          ...formData.eCoDetails,
                          consignedTo: e.target.value,
                        },
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const lines = e.target.value.split("\n");
                        if (lines.length >= 10) {
                          e.preventDefault();
                        }
                      }
                    }}
                  />
                  <h6 className="float-end fw-normal text-small text-secondary">
                    {formData.eCoDetails.consignedTo.length}/345 Max
                  </h6>
                  <hr className="mt-4" />{" "}
                </Form.Group>{" "}
                <Form.Group className="mb-3">
                  <Form.Label>
                    3. Means of transport & route (as far as known)
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    placeholder="Enter Means of transport & route"
                    className="mb-1"
                    rows={2}
                    style={{ resize: "none" }}
                    maxLength={97}
                    value={formData.eCoDetails.meanOfTran}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        eCoDetails: {
                          ...formData.eCoDetails,
                          meanOfTran: e.target.value,
                        },
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const lines = e.target.value.split("\n");
                        if (lines.length >= 2) {
                          e.preventDefault();
                        }
                      }
                    }}
                  />
                  <h6 className="float-end fw-normal text-small text-secondary">
                    {formData.eCoDetails.meanOfTran.length}/97 Max
                  </h6>
                </Form.Group>
              </Col>
              <Col
                md={5}
                className="d-flex flex-column align-items-stretch text-center"
                style={{
                  borderLeft: "1px solid lightgrey",
                  paddingLeft: "10px",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={Logo}
                    width="40%"
                    height="auto"
                    className="img-fluid mb-2"
                    alt="logo"
                    style={{ backgroundColor: "transparent" }}
                  />

                  <h5>Asian Exporters' Chamber of Commerce and Industry</h5>
                  <h6>
                    (Recognized by Ministry of Commerce & Industry, Govt. of
                    India)
                  </h6>
                  <span style={{ fontSize: ".8rem" }}>
                    Regd, Office, 604, 6th Floor, Hilton Center, Plot No, 66,
                    Sector 11, CBD Belapur. Navi Mumbai-400614 (INDIA) Tel:
                    +91-22-4127 1145/46, Fax: +91-22-4127 1147 Email:
                    info@aecci.org.in | Website: www.aecci.org.in CIN:
                    U91900MH2015NPL265816
                  </span>
                </div>
                <div className="mt-auto">
                  <Form.Group className="mb-3 w-100 text-start">
                    <Form.Label>4. For Office use</Form.Label>
                    <Form.Control
                      as="textarea"
                      style={{ resize: "none" }}
                      className="bg-white"
                      rows={2}
                      type="text"
                      placeholder="Enter Exporter business name, address, country"
                      readOnly
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <td style={{ width: "10%" }}>5. item Number</td>
                  <td style={{ width: "15%" }}>
                    6. Marks and number of packages
                  </td>
                  <td style={{ width: "30%" }}>
                    7. Number and kind of packages, description of goods
                  </td>
                  <td style={{ width: "20%" }}>8. Origin Criteria</td>
                  <td style={{ width: "12%" }}>
                    9. Gross weight or order quantity
                  </td>
                  <td style={{ width: "12%" }}>
                    10. Number and date of invoices
                  </td>
                </tr>
              </thead>
              <tbody>
                {formData.eCoDetails.extendedData.map((data, index) => (
                  <>
                    <tr>
                      <td colSpan={6}>Page No. {index + 1}</td>
                    </tr>
                    <tr key={index}>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={40}
                          value={data.itemNo}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              itemNo: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.itemNo.length}/40 Max
                        </h6>
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={240}
                          value={data.noOfPack}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              noOfPack: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.noOfPack.length}/240 Max
                        </h6>
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={1100}
                          value={data.desOfGoods}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              desOfGoods: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={40}
                          value={data.originCriteria}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              originCriteria: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.originCriteria.length}/40 Max
                        </h6>
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={100}
                          value={data.weightAndQuantity}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              weightAndQuantity: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.weightAndQuantity.length}/100 Max
                        </h6>
                      </td>
                      <td>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={20}
                          style={{ resize: "none" }}
                          maxLength={200}
                          value={data.noAndDateInvoice}
                          onChange={(e) => {
                            const newData = [
                              ...formData.eCoDetails.extendedData,
                            ];
                            newData[index] = {
                              ...newData[index],
                              noAndDateInvoice: e.target.value,
                            };
                            setFormData({
                              ...formData,
                              eCoDetails: {
                                ...formData.eCoDetails,
                                extendedData: newData,
                              },
                            });
                          }}
                        />
                        <h6 className="float-end fw-normal text-small text-secondary">
                          {data.noAndDateInvoice.length}/200 Max
                        </h6>
                      </td>
                    </tr>
                  </>
                ))}
                {/* 
//-------------delete page function is here-------------------
--------------------------------------------------------------
-------------------------------------------------------------- */}

                <tr>
                  <td colSpan={6}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <span>Do You Want To Extend Page?</span>
                        <Button
                          variant="info"
                          className="ms-3 py-0 my-0"
                          onClick={handleAddEcoForm}
                        >
                          Yes
                        </Button>
                      </div>

                      {formData.eCoDetails.extendedData.length > 1 && (
                        <>
                          <div
                            className="d-flex align-items-center text-danger cursor-pointer max-content"
                            onClick={() => {
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                eCoDetails: {
                                  ...prevFormData.eCoDetails,
                                  extendedData:
                                    prevFormData.eCoDetails.extendedData.slice(
                                      0,
                                      -1
                                    ), // Remove last element
                                },
                              }));
                            }}
                          >
                            <MdDelete className="fs-3" />

                            <h6 className="mb-0 ms-1">Delete Page</h6>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
            <hr />
            <div className="d-flex">
              <div
                style={{
                  width: "50%",
                  position: "relative",
                }}
                className="d-flex flex-column justify-content-between mr-1"
              >
                <Form.Label className="d-flex ">
                  <h6 className="text-nowrap me-2">
                    11. CERTIFICATION: {"  "}
                  </h6>
                  <span className="text-small">
                    {" "}
                    It is hereby certified on the basis of Control carried out,
                    that the declaration by the exporter is correct.
                  </span>
                </Form.Label>
                <div>
                  <hr
                    style={{
                      position: "absolute",
                      top: "50%",
                      width: "100%",
                      transform: "rotate(55deg)",
                      width: "100%",
                    }}
                  />
                  <hr
                    style={{
                      position: "absolute",
                      top: "50%",
                      width: "100%",
                      transform: "rotate(-55deg)",
                      width: "100%",
                    }}
                  />
                </div>

                <Form.Label className="mb-0">
                  <div style={{ borderBottom: "1px dashed grey" }}></div>
                  <span className="text-small">
                    Authorised Signatory - ASIAN EXPORTERS' CHAMBER OF COMMERCE
                    AND INDUSTRY{" "}
                  </span>
                </Form.Label>
              </div>
              <div style={{ width: "50%", borderLeft: "1px solid grey" }}>
                <div className="ms-2">
                  <span className="text-secondary">
                    Israeli declaration required:
                  </span>
                  <div className="d-inline ms-3 align-middle">
                    <Form.Check
                      inline
                      label="Yes"
                      name="israeliDec"
                      type="radio"
                      checked={formData.eCoDetails.israeliDec === "true"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            israeliDec: "true",
                          },
                        })
                      }
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="israeliDec"
                      type="radio"
                      checked={formData.eCoDetails.israeliDec === "false"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            israeliDec: "false",
                          },
                        })
                      }
                    />
                  </div>

                  <h6 className="my-3">12. DECLARATION BY THE EXPORTERS:</h6>

                  <span>
                    The Undersigned hereby declares that the above details and
                    statements are correct that all the goods were produced in{" "}
                    {"  "}
                    <div className="d-inline-block">
                      <Form.Check
                        className="me-0"
                        inline
                        name="orginOfCom"
                        label="INDIA"
                        type="radio"
                        checked={formData.eCoDetails.orginOfCom === "INDIA"} // Bind value from form data
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            eCoDetails: {
                              ...formData.eCoDetails,
                              orginOfCom: "INDIA",
                            },
                          })
                        }
                      />{" "}
                      <Form.Check
                        className="me-0"
                        inline
                        name="orginOfCom"
                        type="radio"
                        label="FOREIGN ORIGIN"
                        checked={
                          formData.eCoDetails.orginOfCom === "FOREIGN ORIGIN"
                        }
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            eCoDetails: {
                              ...formData.eCoDetails,
                              orginOfCom: "FOREIGN ORIGIN",
                            },
                          })
                        }
                      />
                    </div>{" "}
                    and that they comply with the origin requirements
                  </span>

                  <span className="d-block mt-2 mb-3">for exports to</span>

                  <Form.Group className="w-50">
                    <Form.Label className="mb-0">
                      (Importing Country)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={30}
                      value={formData.eCoDetails.impCountry}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            impCountry: e.target.value,
                          },
                        });
                      }}
                    />
                    <h6 className="float-end fw-normal text-small text-secondary">
                      {formData.eCoDetails.impCountry.length}/30 Max
                    </h6>
                  </Form.Group>

                  <Form.Group className="mt-3 w-50">
                    <Form.Label className="mb-0">FOR</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Company Name here"
                      maxLength={40}
                      value={formData.eCoDetails.forComRegion}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            forComRegion: e.target.value,
                          },
                        });
                      }}
                    />
                    <h6 className="float-end fw-normal text-small text-secondary">
                      {formData.eCoDetails.forComRegion.length}/40 Max
                    </h6>
                  </Form.Group>

                  <Form.Group className="mt-3 w-50">
                    <Form.Label className="mb-0">
                      Authorised Signatory
                    </Form.Label>
                    <Form.Select
                      value={formData.eCoDetails.designation}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            designation: e.target.value,
                          },
                        });
                      }}
                    >
                      <option value="">Select Role</option>
                      {[
                        "Authorised Signatory",
                        "Chairman",
                        "CEO",
                        "Managing Director",
                        "Director",
                        "Partner",
                        "Proprietor",
                        "President",
                        "Vice President",
                        "General Manager (GM)",
                        "Executive Director",
                      ].map((role, index) => (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mt-3 w-50">
                    <Form.Label className="mb-0">Place</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Place here"
                      maxLength={20}
                      value={formData.eCoDetails.place}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            place: e.target.value,
                          },
                        });
                      }}
                    />
                    <h6 className="float-end fw-normal text-small text-secondary">
                      {formData.eCoDetails.place.length}/20 Max
                    </h6>
                  </Form.Group>

                  <Form.Group className="mt-3">
                    <Form.Label className="mb-0">Date</Form.Label>
                    <Form.Control
                      as="input"
                      type="date"
                      className="w-50"
                      placeholder="Enter Place here"
                      value={formData.eCoDetails.date}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            date: e.target.value,
                          },
                        });
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mt-3">
                    <Form.Control
                      as="input"
                      type="file"
                      className="w-50 mb-2"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0]; // Get the first file selected
                        const updatedFormData = {
                          ...formData,
                          eCoDetails: {
                            ...formData.eCoDetails,
                            stamp: file, // Update the stamp field with the selected file
                          },
                        };
                        setFormData(updatedFormData); // Update the formData state
                      }}
                    />
                  </Form.Group>

                  {formData.eCoDetails.stamp && ( // Check if eCoFile exists
                    <div>
                      <img
                        width="15%"
                        height="auto"
                        src={URL.createObjectURL(formData.eCoDetails.stamp)} // Use eCoFile for image source
                        alt="Stamp"
                      />
                    </div>
                  )}

                  <span className="d-block">Authorized Stamp & Signature</span>

                  <div style={{ borderBottom: "1px dashed grey" }}></div>
                  {/* border-bottom: 1px dotted #000; */}
                  <span>
                    Place & date, stamp & signature of authorized signatory
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <iframe
              title="eCO File"
              src={URL.createObjectURL(formData.eCoFile)}
              width="100%"
              height="500px"
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        {!eCoCreated ? (
          <>
            <Button className="setup-bg-color" onClick={handlePreviewSave}>
              PREVIEW & SAVE
            </Button>
            <Button onClick={props.onHide} variant="secondary">
              Close
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setECoCreated(false);
              }}
            >
              EDIT
            </Button>
            <Button
              onClick={() => {
                props.onHide();
                handleSubmitEco();
              }}
              variant="success"
            >
              SUBMIT
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

// //------------------------Modal components ---------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------

// ------------------------preview manual receipt modals----------------------------------
// -----------------------------------------------------------------------------

// ------------------------preview receipt modals----------------------------------
// -----------------------------------------------------------------------------
export function PreviewReceipt({ formData }) {
  const [modalShow, setModalShow] = useState(false);
  const priceControl = getAuthUserlocal()?.priceControl;

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  return (
    <>
      <Button
        className="small-font my-1 fw-semibold me-1 setup-bg-color"
        disabled={!formData.packingList}
        onClick={handleShow}
      >
        Preview Receipt
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        centered
      >
        <Modal.Body className="d-flex justify-content-cente position-relative font1">
          <div className="text-center w-100 d-flex flex-column justify-content-center">
            <div>
              <img
                src={Logo}
                width="15%"
                height="auto"
                className="img-fluid mb-2"
                alt="logo"
                style={{
                  backgroundColor: "transparent",
                }}
              />
            </div>
            <h3>Asian Exporters' Chamber of Commerce And Industry</h3>
            <h6 className="mb-0">
              Regd Office:604, 6th Floor, Hilton Centre, Plot No.66,
              Sector-11,CBD Belapur, Navi Mumbai,
            </h6>
            <h6>Maharashtra, E-mail: info@aecci.org.in</h6>
            <h4 className="fw-semibold">RECEIPT PREVIEW</h4>
            <hr className="bg-dark mb-0" />

            {/* ----------------receipt table start here-------------------------------- */}
            <div className="text-start mb-3">
              <span className="text-small d-block mb-0">Receipt No:</span>
              <span className="text-small d-block mb-0">Document No:</span>
              <span className="text-small d-block mb-0">
                Received From: {getAuthUserlocal().companyName}
              </span>
              <span className="text-small d-block mb-0">
                Address: {getAuthUserlocal().address1}
              </span>
              <span className="text-small d-block mb-0">
                GSTIN: {getAuthUserlocal().gstNo}
              </span>
            </div>

            <Table className="text-start" hover>
              <thead>
                <tr>
                  <th>Srno</th>
                  <th>Particulers</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {formData.eCoFileFlag &&
                  <tr>
                    <td>1</td>
                    <td>e-Co(NP)</td>
                    <td>1</td>
                    <td>Rs. {priceControl?.eco}/-</td>
                    <td>Rs. {priceControl?.eco}/-</td>
                  </tr>
                }
                {formData.dgfteCoFileFlag &&
                  <tr>
                    <td>1</td>
                    <td>DGFT electronic-CO(NP)</td>
                    <td>1</td>
                    <td>Rs. {priceControl?.dgft}/-</td>
                    <td>Rs. {priceControl?.dgft}/-</td>
                  </tr>
                }
                {formData.comInvoiceFlag && (
                  <tr>
                    <td>2</td>
                    <td>Commercial Invoice.</td>
                    <td>1</td>
                    <td>Rs. {priceControl?.comInvoiceEco}/-</td>
                    <td>Rs. {priceControl?.comInvoiceEco}/-</td>
                  </tr>
                )}
                {formData.packingListFlag && (
                  <tr>
                    <td>3</td>
                    <td>Packing List*</td>
                    <td>1</td>
                    <td>Rs. {priceControl?.packListEco}/-</td>
                    <td>Rs. {priceControl?.packListEco} /-</td>
                  </tr>
                )}
                {formData.addDoc1Flag && (
                  <tr>
                    <td>4</td>
                    <td>Additional Document 1</td>
                    <td>1</td>
                    <td>Rs. {priceControl?.addDocEco}/-</td>
                    <td>Rs. {priceControl?.addDocEco} /-</td>
                  </tr>
                )}{" "}
                {formData.addDoc2Flag && (
                  <tr>
                    <td>5</td>
                    <td>Additional Document 2</td>
                    <td>1</td>
                    <td>Rs. {priceControl?.addDocEco}/-</td>
                    <td>Rs. {priceControl?.addDocEco} /-</td>
                  </tr>
                )}{" "}
                {formData.refDocFlag && (
                  <tr>
                    <td>6</td>
                    <td>Reference Document</td>
                    <td>1</td>
                    <td>Rs. 0/-</td>
                    <td>Rs. 0 /-</td>
                  </tr>
                )}
                <tr>
                  <td colSpan={4}>Processing charge</td>
                  <td>Rs. {priceControl?.ecoPc} /-</td>
                </tr>
                <tr>
                  <td colSpan={4}>Total</td>
                  <td>Rs. {formData.totalAmt} /-</td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    Amount In words: {numberToWords(formData.totalAmt)}
                  </td>
                </tr>
                {/* <tr>
                  <td colSpan={5} className="text-center">
                    <Button variant="success" >
                      Processed Payment
                    </Button>
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </div>
          <CloseButton
            variant="danger"
            className="position-absolute top-0 end-0 mt-4 me-4"
            onClick={() => setModalShow(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

// ------------------------preview receipt modals----------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
export function ProcessedPayment({ formData, setIsSubmit, isTrial }) {
  const priceControl = getAuthUserlocal()?.priceControl;
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const [isTrialWallet, setIsTrialWallet] = useState(false);
  const [apiWallet, setApiWallet] = useState(false);
  const [formDataWallet, setFormDataWallet] = useState({
    utrNo: "",
    amount: 0,
    paymentScreenShot: null,
  });

  const handleInputChange = (field, value) => {
    setFormDataWallet((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleFileUpload = (field, file) => {
    if (file.size <= 3 * 1024 * 1024) {
      setFormDataWallet((prevData) => ({
        ...prevData,
        [field]: file,
      }));
    } else {
      console.error("File size exceeds 3MB");
      alert("File size exceeds 3MB");
    }
  };

  //--------PAYMENT FOR TRIAL USER----------------------------
  //----------------------------------------------------------
  //----------------------------------------------------------
  const [addFundApi, setAddFundApi] = useState(false);
  const handleConfirmPaymentWithWalletReq = async (e) => {
    e.preventDefault();

    const validationRulesProceedPayment = {
      utrNo: {
        message: "Please Enter UTR Number.",
        isValid: () => formDataWallet.utrNo.trim() !== "",
      },
      Amount: {
        message: "Please Enter Amount.",
        isValid: () =>
          formDataWallet.amount !== 0 &&
          formDataWallet.amount.toString()[0] !== "0",
      },
      paymentScreenShot: {
        message: "Please Enter Payment Screenshot.",
        isValid: () => formDataWallet.paymentScreenShot !== null,
      },
      utrNo: {
        message: "Please Enter a Valid UTR Number.",
        isValid: () => /^[A-Za-z0-9]{1,22}$/.test(formDataWallet.utrNo.trim()),
      },
    };

    for (const field in validationRulesProceedPayment) {
      const { message, isValid } = validationRulesProceedPayment[field];
      if (!isValid()) {
        alert(message);
        return;
      }
    }

    // const userId = getAuthUserlocal()._id;

    //making form
    const apiFormData = new FormData();
    apiFormData.append("utrNo", formDataWallet.utrNo);
    apiFormData.append("amount", formDataWallet.amount);
    apiFormData.append("paymentScreenShot", formDataWallet.paymentScreenShot);

    const clientId = getAuthUserlocal()._id;
    setAddFundApi(true);
    try {
      const res = await axios.post(
        getURLbyEndPoint("addFundToWallet") + clientId,
        apiFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.status) {
        setFormDataWallet({
          utrNo: "",
          amount: "",
          paymentScreenShot: null,
        });
        setIsSubmit(true);
        setModalShow(false);
        setAddFundApi(false);
      } else {
        alert("Something went Wrong!");
      }
    } catch (error) {
      alert("Server is not responding!!");
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    } finally {
      setAddFundApi(false);
    }
  };
  const handleConfirmPayment = () => {
    const balanceAfterTransaction = user?.balance - formData.totalAmt;

    if (isTrial && balanceAfterTransaction <= 0) {
      setIsTrialWallet(true);
    } else {
      setIsSubmit(true);
      setModalShow(false);
    }
  };

  // const handleConfirmPaymentWithWalletReq = () => {
  //   setIsSubmit(true);
  //   setModalShow(false);
  // };
  return (
    <>
      <Button
        variant="success"
        className="small-font my-1 fw-semibold"
        disabled={!formData.packingList}
        onClick={handleShow}
      >
        Processed Payment
      </Button>

      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        {" "}
        <Modal.Body>
          <div>
            <h5>Payment Process</h5>
            <hr />
            <Card>
              <Card.Header>
                <Row className="d-flex">
                  <Col md={7}>
                    <h6 className="text-medium">
                      {getAuthUserlocal().companyName}
                    </h6>
                    <h6 className="text-medium">
                      Membership No: {getAuthUserlocal().memberShipNo}
                    </h6>
                  </Col>
                  <Col md={5}>
                    {" "}
                    <h6 className="text-medium">
                      Wallet Balance: ₹{getAuthUserlocal().balance}
                    </h6>
                    <h6 className="text-medium">
                      Valid Upto: {getAuthUserlocal().validUpTo}
                    </h6>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {isTrialWallet ? (
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h6>UTR NO.</h6>
                      <Form.Control
                        className="w-75"
                        type="text"
                        placeholder="Enter Utr No."
                        required
                        value={formDataWallet.utrNo}
                        onChange={(e) =>
                          handleInputChange("utrNo", e.target.value)
                        }
                        maxLength={25}
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h6>AMOUNT</h6>
                      <Form.Control
                        className="w-75"
                        type="text"
                        maxLength={6}
                        placeholder="Enter Amount"
                        required
                        value={formDataWallet.amount}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          // Check if the input is a number or an empty string
                          if (/^\d*$/.test(inputValue) || inputValue === "") {
                            // Check if the first digit is not zero
                            if (
                              inputValue.length === 0 ||
                              (inputValue.length === 1 && inputValue !== "0")
                            ) {
                              handleInputChange("amount", inputValue);
                            } else {
                              // Remove leading zeros
                              handleInputChange(
                                "amount",
                                inputValue.replace(/^0+/, "")
                              );
                            }
                          }
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <h6 className="me-3">PAYMENT ScreenShot (MAX 3MB)</h6>
                      <div className="me-3">
                        <label
                          className={
                            formDataWallet.paymentScreenShot
                              ? "btn btn-success"
                              : "btn btn-primary"
                          }
                          htmlFor="paymentScreenShotWallet"
                        >
                          {formDataWallet.paymentScreenShot
                            ? "Uploaded"
                            : "Choose File"}
                        </label>
                        <input
                          style={{
                            display: "none",
                          }}
                          type="file"
                          accept="image/*"
                          name="paymentScreenShot"
                          id="paymentScreenShotWallet"
                          onChange={(e) =>
                            handleFileUpload(
                              "paymentScreenShot",
                              e.target.files[0]
                            )
                          }
                        />
                      </div>
                      {formDataWallet.paymentScreenShot && (
                        <a
                          href={URL.createObjectURL(
                            formDataWallet.paymentScreenShot
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <BsEye className="icon-size" />
                        </a>
                      )}
                    </div>

                    <div className="text-center">
                      <Button
                        variant="success"
                        className="rounded-5 px-5 text-medium"
                        disabled={addFundApi}
                        onClick={handleConfirmPaymentWithWalletReq}
                      >
                        {addFundApi ? "Processing..." : "CONFIRM PAYMENT"}
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="ms-5 mb-2">
                      <h6 className="text-medium">
                        Debit Amount: ₹{formData.totalAmt}
                      </h6>
                      <h6 className="text-medium">
                        Payment Towards: Electronic Certificate of Origin
                      </h6>
                    </div>
                    <Card className="p-2 mb-3">
                      <Card.Text className="fs-5 mb-0">
                        Informative Note:
                      </Card.Text>

                      <ul>
                        <li>
                          Charges for the issuance of a certificate of origin
                          will be deducted from the wallet.
                        </li>
                        <li>
                          Maintain a minimum balance of Rs. 3000/- in your
                          wallet.
                        </li>
                        <li>
                          Initiate wallet payments only from the company&#39;s
                          registered and authorized bank account.
                        </li>
                        <li>
                          The beneficiary Account displayed on this platform is
                          unique to you only and is not be shared with anyone
                          else.
                        </li>
                        <li>
                          <Card.Text className="text-medium">
                            In Case of Rejection, Amount will be Credited to
                            your wallet.
                          </Card.Text>
                        </li>
                        <li>
                          <Card.Text className="text-medium">
                            You can monitor Transaction entires in your wallet.
                          </Card.Text>
                        </li>{" "}
                        <li>
                          <Card.Text className="text-medium">
                            For Any Assistant, contact Chamber Desk.
                          </Card.Text>
                        </li>
                      </ul>
                    </Card>
                    <div className="text-center">
                      <Button
                        variant="success"
                        className="rounded-5 px-5 text-medium"
                        onClick={handleConfirmPayment}
                      >
                        CONFIRM PAYMENT
                      </Button>
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export function ProcessedPaymentMco({ formData, setIsSubmit }) {
  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  return (
    <>
      <Button
        variant="success"
        className="small-font my-1 fw-semibold"
        disabled={!formData.packingList}
        onClick={handleShow}
      >
        Processed Payment
      </Button>

      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        {" "}
        <Modal.Body>
          <div>
            <h5>Payment Process</h5>
            <hr />
            <Card>
              <Card.Header>
                <Row className="d-flex">
                  <Col md={7}>
                    <h6 className="text-medium">
                      {getAuthUserlocal().companyName}
                    </h6>
                    <h6 className="text-medium">
                      Membership No: {getAuthUserlocal().memberShipNo}
                    </h6>
                  </Col>
                  <Col md={5}>
                    {" "}
                    <h6 className="text-medium">
                      Wallet Balance: ₹{getAuthUserlocal().balance}
                    </h6>
                    <h6 className="text-medium">
                      Valid Upto: {getAuthUserlocal().validUpTo}
                    </h6>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <div className="ms-5 mb-2">
                  <h6 className="text-medium">
                    Debit Amount: ₹{formData.totalAmt}
                  </h6>
                  <h6 className="text-medium">
                    Payment Towards: Semi-Electronic Certificate of Origin
                  </h6>
                </div>

                <Card className="p-2 mb-3">
                  <Card.Text className="fs-5 mb-0">Informative Note:</Card.Text>

                  <ul>
                    <li>
                      Charges for the issuance of a certificate of origin will
                      be deducted from the wallet.
                    </li>
                    <li>
                      Maintain a minimum balance of Rs. 3000/- in your wallet.
                    </li>
                    <li>
                      Initiate wallet payments only from the company&#39;s
                      registered and authorized bank account.
                    </li>
                    <li>
                      The beneficiary Account displayed on this platform is
                      unique to you only and is not be shared with anyone else.
                    </li>
                    <li>
                      <Card.Text className="text-medium">
                        In Case of Rejection, Amount will be Credited to your
                        wallet.
                      </Card.Text>
                    </li>
                    <li>
                      <Card.Text className="text-medium">
                        You can monitor Transaction entires in your wallet.
                      </Card.Text>
                    </li>{" "}
                    <li>
                      <Card.Text className="text-medium">
                        For Any Assistant, contact Chamber Desk.
                      </Card.Text>
                    </li>
                  </ul>
                </Card>

                <div className="text-center">
                  <Button
                    variant="success"
                    className="rounded-5 px-5 text-medium"
                    onClick={() => {
                      setIsSubmit(true);
                      setModalShow(false);
                    }}
                  >
                    CONFIRM PAYMENT
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

// --------------------------------tips modals----------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
function TradeDocumentationTip() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">Trade Document</h5>
        </div>
        <p>
          {" "}
          <strong>There are three types of Trade Documents:</strong>
        </p>
        <ul className="text-small">
          <li>Electronic Certificate of Origin (eCO)</li>
          <li>Semi- Electronic Certificate of Origin (SeCO)</li>
          <li>Non- Standard Documents (NSD) </li>
        </ul>
        <span>
          Before proceeding, user must read each and every tip given below under
          each section.
        </span>
      </div>
    </>
  );
}

function DraftTip() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">Draft</h5>
        </div>
        <p>
          {" "}
          <strong>Tips for Draft:</strong>
        </p>
        <ul className="text-small">
          <li>
            Saved drafts expire after 7 days. Complete and submit drafts within
            this timeframe to avoid losing progress.
          </li>
          <li>
            If saving drafts, ensure that the Uploaded Pdf file size does not
            exceed 15 MB (all inclusive) or 3 pages each. This helps maintain
            system efficiency.
          </li>
          <li>Enter Draft name to identify them later.</li>
          <li>
            Maximum 10 drafts can be saved. Routinely clean up saved drafts and
            delete any outdated or unnecessary files.
          </li>
        </ul>
      </div>
    </>
  );
}

function EcoButton() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Tips for Electronic Certificate of Origin
          </h5>
        </div>
        <ul className="text-small">
          <li>No page limitations for Electronic Certificate of Origin</li>
          <li>
            Approved e-Co printed at the user's premises are digitally stamped,
            signed, and dated.
          </li>
          <li>
            Users can download each copy up to three times, and the download
            option will be disabled thereafter.
          </li>
        </ul>
        <span>
          Before Submission, please carefully review all the information. Any
          incorrect documents may cause processing delays.
        </span>
      </div>
    </>
  );
}

function ManualCoButton() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Tips for Semi-Electronic Certificate of Origin
          </h5>
        </div>
        <ul className="text-small">
          <li>1 original (limited to a maximum of 2 pages)</li>
          <li>
            Chamber will handle the printing, stamping, and manual signing of
            your document.
          </li>
          <li>
            Ideally, For Bank and LC transactions, it's preferable to provide a
            manual document, (with 2-3 copies needed). Users should submit as
            many times as the number of copies required.
          </li>
        </ul>
        <span>
          Before Submission, please carefully review all the information. Any
          incorrect documents may cause processing delays.
        </span>
      </div>
    </>
  );
}
function NonStandButton() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Tips for Non- Standard Documents:
          </h5>
        </div>
        <ul className="text-small">
          <li>
            Documents such as Pharma/ Agrochemical Product Registration,
            Commercial Trade agreements, POA, LOA, FSC, etc. can be Attested
            here.
          </li>
          <li>
            Approved Documents are printed at the user&#39;s premises are
            digitally stamped, signed, and dated.
          </li>
        </ul>
        <span>
          Before Submission, please carefully review all the information. Any
          incorrect documents may cause processing delays.
        </span>
      </div>
    </>
  );
}

function TrackDocButton() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3 mb-3 w-100">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Tips for Track Your Document
          </h5>
        </div>
        <ul className="">
          <li>
            All uploaded Certificates of Origin (COO) can be conveniently
            tracked here.
          </li>
          <li>
            The approval of documents is solely at the discretion of the AECCI.
          </li>
          <li>
            It is advisable to take daily backups of your documents to prevent
            any inconvenience or loss of data.
          </li>
        </ul>
      </div>
    </>
  );
}

export function ElectronicCo() {
  return (
    <>
      <div className="border p-3">
        <h6 className='text-sm fw-bold text-center text-danger'><IoWarning className='fs-4' height='20' width='auto' /> This service is currenly unavilable</h6>
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">TIPS for e-Co</h5>
        </div>
        <h6>For e-Co preparation, follow the below guidelines:</h6>
        <ul className="text-small">
          <li>All the columns of e-Co are mandatory to fill up.</li>
          <li>No page limitations for Digital Print.</li>
          <li>
            Commercial Invoice, Packing List, and other documents must be handy
            in PDF format only; Maximum size of the file being uploaded should
            be 5MB.
          </li>
          <li>
            If Means of transport by road, then the user must upload the LR
            copies for reference.
          </li>
          <li>
            Israeli Declaration - If the shipment is for Gulf Country then a
            mandatory tick “YES”.
          </li>
        </ul>

        <h5 className="ms-5 text-decoration-underline">
          Special Note for Column No. 12
        </h5>

        <ul className="text-small">
          <li>
            DECLARATION BY THE EXPORTER, the default origin country will be
            India. If shipment is Re-export/ foreign origin then the user must
            specifically choose foreign Origin in the tab given.
          </li>
          <li>
            If Re-export/ Foreign Origin Shipment, Upload Origin Country COO
            must in “Reference Document” Section.
          </li>
          <li>
            Should be filled up in capital letters only; Importing country name
            must match in Invoice, Packing List and other document.
          </li>
          <li>
            Authorized Signature being uploaded must match with the Annexure of
            the list of Authorized signatories given in Chamber.
          </li>
          <li>Place of issue and date must mention.</li>
          <li>
            Before submission – review the complete information carefully and
            then submit. If any information is found to be incorrect, this may
            lead to a delay in processing or rejection of the request.
          </li>
        </ul>
      </div>
    </>
  );
}


export function DgftTip() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">TIPS for attestation of Shipping documents relevant to <br/> DGFT issued e-COO (NP)</h5>
        </div>
        <h6>To get your Invoice, packing list and other shipping documents, attested, Please take note of the below points:</h6>
        <ul className="text-small">
          <li>COO(NP) issued by the DGFT can be uploaded here.</li>
          <li>Clear Copy of COO (NP) is to be uploaded in PDF format.</li>
          <li>
            Size of the each uploaded document must be less than 5 MB.
          </li>
          <li>
            Carefully enter the COO(NP) reference number issued by the DGFT. It should be exactly matching with no variation whether in numbers, letters, or format etc,
          </li>
          <li>
            Clear copy of desired shipping documents for attestation such as Invoice,
            Packing List, Shipping Bill, Bill of Lading, Fumigation Certificate, Veterinary
            Certificate, Certificate of Analysis, Phytosanitary Certificate, Non-GMO
            Certificate etc. are to be uploaded here on the AECCI platform.
          </li>
        </ul>
      </div>
    </>
  );
}

export function ComInvoiceTip() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Special Notes tips for Commercial Invoice
          </h5>
        </div>
        <h6>Submitting Commercial Invoice, refer below TIPS:</h6>
        <ul className="text-small">
          <li>
            Commercial Invoice must be handy in PDF format only, Maximum size of
            the file being uploaded should be 5MB
          </li>
          <li>No page limitations for Digital Print</li>
          <li>
            Signature on Commercial Invoice must match the signature uploaded in
            the e-Co
          </li>
          <li>
            If Chamber attestations not required for the invoice tick “NO”, but
            ensure to upload it for reference purposes.
          </li>
          <li>
            The commercial invoice should include a designated space at the
            bottom for the attestation by the Chamber (as illustrated in the
            image).
          </li>
          <li>
            Before submission – review the complete information carefully and
            then submit. If any information is found to be incorrect, this may
            lead to a delay in processing or rejection of the request.
          </li>
        </ul>
      </div>
    </>
  );
}

export function PackListTip() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Special Notes tips for Packing List
          </h5>
        </div>
        <h6>Submitting Packing List, refer below TIPS:</h6>
        <ul className="text-small">
          <li>No page limitations for Digital Print</li>
          <li>
            Packing List must be handy in PDF format only, Maximum size of the
            file being uploaded should be 5MB
          </li>
          <li>
            Signature on Packing List must match the signature uploaded in the
            e-Co.
          </li>
          <li>
            If Chamber attestation not required for the Packing List tick “NO”,
            but ensure to upload it for reference purposes.
          </li>
          <li>
            Packing List should include a designated space at the bottom for the
            attestation by the Chamber (as illustrated in the image).
          </li>
          <li>
            Before submission – review the complete information carefully and
            then submit. If any information is found to be incorrect, this may
            lead to a delay in processing or rejection of the request.
          </li>
        </ul>
      </div>
    </>
  );
}

export function AddDocTips() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Special Notes tips for Additional Document
          </h5>
        </div>
        <h6>Submitting Additional Documents refer below TIPS:</h6>
        <ul className="text-small">
          <li>
            Additional Documents must be handy in PDF format only; Maximum size
            of the file being uploaded should be 5MB.
          </li>
          <li>No page limitations for Digital Print.</li>
          <li>
            Health Certificate/ Phyto Certificate/ Fumigation Certificate anyone
            can be uploaded in Additional Documents for attestation.
          </li>
          <li>Must Upload Clear and visible copies of documents.</li>
          <li>
            Before submission – review the complete information carefully and
            then submit. If any information is found to be incorrect, this may
            lead to a delay in processing or rejection of the request.
          </li>
        </ul>
      </div>
    </>
  );
}

export function RefDocTips() {
  return (
    <>
      <div className="border p-3">
        <div className="d-flex justify-content-center gap-3">
          <BsFillInfoCircleFill className="icon-size-1" />
          <h5 className="text-decoration-underline">
            Special Notes tips for Reference Document
          </h5>
        </div>
        <h6>Submitting Reference Documents, refer below TIPS:</h6>
        <ul className="text-small">
          <li>
            Documents uploaded here only for chamber records; no attestation
            will be provided.
          </li>
          <li>
            Reference documents must be handy in PDF Format only; maximum size
            of the file being uploaded should be 5MB.
          </li>
          <li>By Road Shipments, Upload LR Copies.</li>
          <li>
            If Re-export/ Foreign Origin Shipment, Upload Origin Country COO
            must.
          </li>
          <li>
            Before submission – review the complete information carefully and
            then submit. If any information is found to be incorrect, this may
            lead to a delay in processing or rejection of the request.
          </li>
        </ul>
      </div>
    </>
  );
}
export const readAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // When the file is loaded
    reader.onload = () => {
      // Resolve the Promise with the result
      resolve(reader.result);
    };

    // If an error occurs during reading the file
    reader.onerror = () => {
      // Reject the Promise with the error
      reject(reader.error);
    };

    // Start reading the file
    reader.readAsDataURL(file);
  });
};




