import React from "react";
// import EPlatformHeaderimg from "../../assets/EplatformHeaderimg.avif";
import WelcomeBanner from "../../assets/WelcomeBanner.avif";
import verificationBanner from "../../assets/verificationBanner.png";

const EPlatformHeader = ({ heading, text, text2, text3, sideImg }) => {
  return (
    <>
     <div
  className="d-flex justify-content-center text-light font1"
  style={{
    backgroundImage: heading === "Verification Portal" ? `url(${verificationBanner})` : `url(${WelcomeBanner})`,
    backgroundSize: "cover",
  }}
>
  <div
    className="main-width d-flex flex-wrap align-items-center"
    style={{ marginBottom: "5rem", marginTop: "5rem" }}
  >
    {/* Left Content */}
    <div className="content flex-grow-1">
      <h2 className="font1">{heading || "e-Platform"}</h2>
      <div>
        <span>{text}</span>
        {text2 &&
        <div>
        <span className="fs-4">{text2}</span>
        </div>
      }

      {text3 && 
      <div>
        <span className="fs-4">{text3}</span>
        </div>
      }
      </div>
    </div>
    {/* Side Image */}
    {sideImg && (
      <div className="image-container">
        <img src={sideImg} alt="Side Image" className="img-responsive cover" width={300} />
      </div>
    )}
  </div>
</div>

    </>
  );
};

export default EPlatformHeader;